import React from "react"
import { Snackbar, Alert, AlertProps } from "@mui/material"

interface Props {
  snackbar: Pick<AlertProps, "children" | "severity"> | null
  setSnackbar: React.Dispatch<Pick<AlertProps, "children" | "severity"> | null>
}

export default function SnackbarWrapper(props: Props) {
  const handleCloseSnackbar = () => props.setSnackbar(null)

  if (!props.snackbar) return null
  return (
    <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
      <Alert {...props.snackbar} onClose={handleCloseSnackbar} />
    </Snackbar>
  )
}
