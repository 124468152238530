import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import Property from "../../../types/Property"
import EditPropertyForm from "../EditPropertyForm/EditPropertyForm"
import { Close } from "@mui/icons-material"
import { BSON } from "realm-web"

interface CreatePropertyDialogProps {
  open: boolean
  onSave: (property: Property) => void
  onClose: () => void
}

const emptyProperty: Property = {
  _id: new BSON.ObjectId(),
  projectId: new BSON.ObjectId(),
}

export default function CreatePropertyDialog(props: CreatePropertyDialogProps) {
  const [newProperty, setNewProperty] = useState<Property>(emptyProperty)

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose()
      }}
    >
      <Stack direction="column" spacing={1} sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            Add property
          </Typography>
          <IconButton
            onClick={() => {
              props.onClose()
            }}
          >
            <Close />
          </IconButton>
        </Box>

        <Box>
          <EditPropertyForm
            property={emptyProperty}
            onChange={(property) => {
              setNewProperty(property)
            }}
          />
        </Box>

        <Button
          variant="contained"
          onClick={() => {
            props.onSave(newProperty)
          }}
        >
          Save
        </Button>
      </Stack>
    </Dialog>
  )
}
