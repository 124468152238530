import { Paper } from "@mui/material"
import BagDropButton from "../BagDropButton/BagDropButton"
import UndoBagDropButton from "../UndoBagDropButton/UndoBagDropButton"

interface Props {
  onDropBag: () => void
  dropBagDisabled: boolean
  onUndo: () => void
  undoDisabled: boolean
}

export default function BagDropActions(props: Props) {
  return (
    <Paper
      sx={{
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: 4,
        display: "flex",
        flexDirection: "row",
        gap: 3,
        p: 2,
        borderRadius: 4,
      }}
    >
      <UndoBagDropButton onClick={props.onUndo} disabled={props.undoDisabled} />
      <BagDropButton
        onClick={props.onDropBag}
        disabled={props.dropBagDisabled}
      />
    </Paper>
  )
}
