import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Project, { ProjectStatus } from "../../../types/Project"
import { useRealmApp } from "../../RealmApp/RealmApp"

interface Props {
  project: Project
  onChange: (project: Project) => void
}

export default function EditProjectForm(props: Props) {
  const [formProject, setFormProject] = useState<Project>(props.project)

  useEffect(() => {
    if (!props.project) return
    setFormProject(props.project)
  }, [props.project])

  const handleFormChangeNumber = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "") {
      setFormProject({ ...formProject, [event.target.name]: "" })
    } else {
      setFormProject({
        ...formProject,
        [event.target.name]: parseFloat(event.target.value) ?? undefined,
      })
    }
  }
  const handleFormChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormProject({ ...formProject, [event.target.name]: event.target.value })
  }
  const handleSelectChange = async (event: SelectChangeEvent<unknown>) => {
    setFormProject({ ...formProject, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    if (!formProject) return
    props.onChange(formProject)
  }, [formProject])

  const { currentUser } = useRealmApp()
  const userRole = currentUser?.customData.role

  return (
    <div>
      <Stack spacing={2} sx={{ px: 0, pt: 2 }}>
        <Box
          component="form"
          sx={{ width: 250, mx: "auto", rowGap: 1 }}
          display="grid"
        >
          <Box>
            <TextField
              label="Name"
              name="name"
              sx={{ width: "100%" }}
              value={formProject?.name || ""}
              onChange={handleFormChange}
            ></TextField>
          </Box>

          <Box>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="form-status">Status</InputLabel>
              <Select
                labelId="form-status"
                label="Status"
                name="status"
                value={formProject?.status || ""}
                onChange={handleSelectChange}
              >
                <MenuItem value={ProjectStatus.UNKNOWN}>
                  Unknown status
                </MenuItem>
                <MenuItem value={ProjectStatus.EDITING}>Editing</MenuItem>
                <MenuItem value={ProjectStatus.READY}>
                  Ready for harvesting
                </MenuItem>
                <MenuItem value={ProjectStatus.ONGOING}>
                  Harvesting ongoing
                </MenuItem>
                <MenuItem value={ProjectStatus.COMPLETED}>
                  Harvesting completed
                </MenuItem>
                <MenuItem value={ProjectStatus.LOCKED}>
                  Locked and invoiced
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          {userRole === "admin" && (
            <>
              <Box>
                <TextField
                  label="Tonnes harvested"
                  name="tonnesHarvested"
                  type="number"
                  sx={{ width: "100%" }}
                  value={formProject?.tonnesHarvested ?? ""}
                  onChange={handleFormChangeNumber}
                ></TextField>
              </Box>

              <Box>
                <TextField
                  label="Price per tonne"
                  name="tonnePrice"
                  type="number"
                  sx={{ width: "100%" }}
                  value={formProject?.tonnePrice ?? ""}
                  onChange={handleFormChangeNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">NOK</InputAdornment>
                    ),
                  }}
                ></TextField>
              </Box>
            </>
          )}
        </Box>
      </Stack>
    </div>
  )
}
