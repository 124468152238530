import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import PropertyNumber from "../../../types/PropertyNumber"
import PropertyOwner from "../../../types/PropertyOwner"
import { Close, PropaneTankRounded } from "@mui/icons-material"
import EditPropertyNumberForm from "./PropertyNumberForm"
import { useState } from "react"
import { BSON } from "realm-web"

interface AddPropertyNumberDialog {
  open: boolean
  onClose: () => void
  onSave: (propertyNumber: PropertyNumber) => void
}

const newPropertyNumber = {
  _id: new BSON.ObjectId(),
  propertyNumber: "",
}

export default function AddPropertyNumberDialog(
  props: AddPropertyNumberDialog
) {
  const [propertyNumber, setPropertyNumber] =
    useState<PropertyNumber>(newPropertyNumber)

  const onChange = (propertyNumber: PropertyNumber) => {
    setPropertyNumber(propertyNumber)
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose()
        }}
      >
        <Stack direction="column" spacing={1} sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h2">
              Add property number
            </Typography>
            <IconButton
              onClick={() => {
                props.onClose()
              }}
            >
              <Close />
            </IconButton>
          </Box>

          <Box>
            <EditPropertyNumberForm onChange={onChange} />
          </Box>

          <Button
            variant="contained"
            onClick={() => {
              props.onSave({ ...propertyNumber, _id: new BSON.ObjectID() })
            }}
          >
            Save
          </Button>
        </Stack>
      </Dialog>
    </div>
  )
}
