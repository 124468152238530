import {
  AppBar,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import PropertyOwner from "../../../types/PropertyOwner"
import { Close, Send } from "@mui/icons-material"

interface Props {
  open: boolean
  owner?: PropertyOwner
  onSendSMS: (owner: PropertyOwner) => void
  onClose: () => void
}

export function SendOneSMSDialog(props: Props) {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose()
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            {props.onClose && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.onClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
            )}
            <DialogTitle>
              Send SMS Asking for Consent and Account Number
            </DialogTitle>
          </Toolbar>
        </AppBar>
        <Stack sx={{ py: 2, px: 4 }} spacing={2}>
          <Typography
            variant="body1"
            sx={{ mb: 2 }}
            color="grey.500"
            textAlign="center"
          >
            Owner: {props.owner?.name}
          </Typography>
          <Button
            variant="contained"
            size="large"
            endIcon={<Send />}
            onClick={() => {
              props.owner && props.onSendSMS(props.owner)
            }}
            disabled={!props.owner || !props.owner?.phoneNumber}
          >
            Send SMS to {props.owner?.phoneNumber}
          </Button>
        </Stack>
      </Dialog>
    </>
  )
}
