import React, { useEffect } from "react"
import {
  TextField,
  Button,
  Container,
  Card,
  AlertProps,
  Box,
} from "@mui/material"
import "./LoginPage.css"
import { useRealmApp } from "../RealmApp/RealmApp"
import * as Realm from "realm-web"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate, useLocation } from "react-router-dom"
import SnackbarWrapper from "../SnackbarWrapper/SnackbarWrapper"

export default function LoginPage() {
  const realmApp = useRealmApp()

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)
  const [resetPasswordEmail, setResetPasswordEmail] = React.useState<string>("")

  const location = useLocation()
  const navigate = useNavigate()

  const redirectNow = () => {
    const redirectTo = location.search.replace("?redirectTo=", "")
    navigate(redirectTo ? redirectTo : "/")
  }

  const loadUser = async () => {
    if (realmApp.currentUser) {
      const fetchedUser = await realmApp.fetchUser()
      if (fetchedUser) {
        redirectNow()
      }
    }
  }

  useEffect(() => {
    loadUser()
  }, [])

  const clearErrors = () => {
    setSnackbar(null)
  }

  const onFormSubmit = async ({
    email,
    password,
  }: {
    email: FormDataEntryValue
    password: FormDataEntryValue
  }) => {
    clearErrors()
    try {
      const strEmail = email as string
      const strPassword = password as string

      await realmApp.logIn(
        Realm.Credentials.emailPassword(
          strEmail.toLowerCase().trim(),
          strPassword
        )
      )
      redirectNow()
    } catch (err) {
      handleAuthenticationError(err, setSnackbar)
    }
  }

  const sendResetPasswordEmail = async () => {
    try {
      await realmApp.realmApp.emailPasswordAuth.sendResetPasswordEmail(
        resetPasswordEmail.toLowerCase().trim()
      )
      setSnackbar({
        children: `Reset password e-mail sent to ${resetPasswordEmail}`,
        severity: "info",
      })
    } catch (error) {
      if (error instanceof Error) {
        setSnackbar({ children: error.message, severity: "error" })
      }
    }
  }

  return (
    <>
      <Container maxWidth="sm" className="main-container">
        <Box textAlign="center">
          <h1>Algea Map Admin</h1>
        </Box>
        <Card className="auth-card">
          <form
            className="auth-form"
            onSubmit={(e) => {
              e.preventDefault()
              const formData = new FormData(e.target as HTMLFormElement)
              const { email, password } = Object.fromEntries(formData.entries())
              onFormSubmit({ email, password })
            }}
          >
            <TextField
              id="input-email"
              name="email"
              label="Email"
              variant="outlined"
              onChange={(e) => setResetPasswordEmail(e.target.value)}
            />
            <TextField
              id="input-password"
              name="password"
              label="Password"
              variant="outlined"
              type="password"
            />
            <Button type="submit" variant="contained">
              Sign in
            </Button>
          </form>
          <Button onClick={sendResetPasswordEmail}>
            Forgot your password?
          </Button>
        </Card>
      </Container>
      <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />
    </>
  )
}

function handleAuthenticationError(
  err: any,
  setError: React.Dispatch<Pick<AlertProps, "children" | "severity"> | null>
) {
  console.error(err)
  const handleUnknownError = () => {
    setError({
      children: "Something went wrong. Try again later",
      severity: "error",
    })
  }
  if (err instanceof Realm.MongoDBRealmError) {
    const { error, statusCode } = err
    const errorType = error || statusCode
    switch (errorType) {
      case "invalid username":
        setError({ children: "Invalid e-mail", severity: "error" })
        break
      case "invalid username/password":
      case "invalid password":
      case 401:
        setError({ children: "Invalid e-mail/password", severity: "error" })
        break
      default:
        handleUnknownError()
        break
    }
  } else {
    handleUnknownError()
  }
}
