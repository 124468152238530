import React, { PropsWithChildren, useContext, useState } from "react"
import { Snackbar, SnackbarProps } from "./Snackbar"

interface Props {
  snackbar: SnackbarProps | null
  setSnackbar: Function
}

const SnackbarContext = React.createContext<Props | null>(null)

export function SnackbarProvider(props: PropsWithChildren) {
  const [snackbar, setSnackbar] = useState<SnackbarProps | null>(null)

  return (
    <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
      {props.children}
      <Snackbar {...snackbar} />
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => {
  const snackbarContext = useContext(SnackbarContext)
  if (snackbarContext) {
    return snackbarContext.setSnackbar
  }
  return () => {}
}
