import { Delete, Edit } from "@mui/icons-material"
import {
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material"
import { Box, Stack } from "@mui/system"
import GDPRIcon from "./GDPRIcon/GDPRIcon"
import Property from "../../../types/Property"
import { grey } from "@mui/material/colors"
import PropertyNumber from "../../../types/PropertyNumber"
import PropertyOwner from "../../../types/PropertyOwner"
import { useState } from "react"
import AddPropertyNumberDialog from "../PropertyNumberDialog/AddPropertyNumberDialog"
import { useMongoDB } from "../../MongoDB/MongoDB"
import EditPropertyNumberDialog from "../PropertyNumberDialog/EditPropertyNumberDialog"
import { EditOwnerDialog } from "../EditOwnerDialog/EditOwnerDialog"
import { SendOneSMSDialog } from "../SendOneSMSDialog/SendOneSMSDialog"
import { useRealmApp } from "../../RealmApp/RealmApp"
import { useSnackbar } from "../../Snackbar/SnackbarContext"
import { BSON } from "realm-web"

interface Props {
  property: Property
  propertyNumbers: PropertyNumber[]
  propertyOwners: PropertyOwner[]
  index: number
  onEditProperty: (property: Property) => void
  onChange: () => void
}

export default function PropertyRow(props: Props) {
  const setSnackbar = useSnackbar()

  const { db } = useMongoDB()

  const [openAddPropertyNumberDialog, setOpenAddPropertyNumberDialog] =
    useState<boolean>(false)
  const [openEditPropertyNumberDialog, setOpenEditPropertyNumberDialog] =
    useState<boolean>(false)
  const [propertyNumberToEdit, setPropertyNumberToEdit] =
    useState<PropertyNumber | null>(null)

  const [openEditOwnerDialog, setOpenEditOwnerDialog] = useState<boolean>(false)
  const [ownerToEdit, setOwnerToEdit] = useState<PropertyOwner | null>(null)

  const [openSendOneSMSDialog, setOpenSendOneSMSDialog] =
    useState<boolean>(false)

  const addPropertyNumber = async (propertyNumber: PropertyNumber) => {
    try {
      const { insertedId } = await db
        .collection("propertyNumbers")
        .insertOne(propertyNumber)

      await db
        .collection("properties")
        .findOneAndUpdate(
          { _id: props.property._id },
          { $push: { propertyNumberIds: insertedId } },
          { returnNewDocument: true }
        )

      props.onChange()
    } catch (error) {
      console.log(error)
    }
  }

  const updatePropertyNumber = async (propertyNumber: PropertyNumber) => {
    try {
      await db
        .collection("propertyNumbers")
        .findOneAndUpdate(
          { _id: propertyNumber._id },
          { $set: propertyNumber },
          { returnNewDocument: true }
        )
    } catch (error) {
      console.log(error)
    }
    props.onChange()
  }

  const deletePropertyNumber = async (propertyNumber: PropertyNumber) => {
    try {
      await db
        .collection("propertyNumbers")
        .findOneAndDelete({ _id: propertyNumber._id })
    } catch (error) {
      console.log(error)
    }

    try {
      await db
        .collection("properties")
        .findOneAndUpdate(
          { _id: props.property._id },
          { $pull: { propertyNumberIds: propertyNumber._id } }
        )
    } catch (error) {
      console.log(error)
    }

    props.onChange()
  }

  const addOwnerOnPropertyNumber = async (
    propertyNumberId: BSON.ObjectID,
    ownerId: BSON.ObjectId,
    share: number | null
  ) => {
    try {
      await db
        .collection("propertyNumbers")
        .findOneAndUpdate(
          { _id: propertyNumberId },
          { $push: { owners: { ownerId: ownerId, share: share } } },
          { returnNewDocument: true }
        )
    } catch (error) {
      console.log(error)
    }
  }

  const replaceOwnerOnPropertyNumber = async (
    propertyNumberId: BSON.ObjectID,
    oldOwnerId: BSON.ObjectId,
    newOwnerId: BSON.ObjectId,
    newShare: number | null
  ) => {
    try {
      await db.collection("propertyNumbers").findOneAndUpdate(
        { _id: propertyNumberId, "owners.ownerId": oldOwnerId },
        {
          $set: {
            "owners.$.ownerId": newOwnerId,
            "owners.$.share": newShare,
          },
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const updateShareOnPropertyNumber = async (
    propertyNumberId: BSON.ObjectID,
    ownerId: BSON.ObjectId,
    share: number | null
  ) => {
    try {
      await db.collection("propertyNumbers").findOneAndUpdate(
        { _id: propertyNumberId, "owners.ownerId": ownerId },
        {
          $set: {
            "owners.$.share": share,
          },
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const removeOwnerOnPropertyNumber = async (
    propertyNumberId: BSON.ObjectID,
    ownerId: BSON.ObjectId
  ) => {
    try {
      await db
        .collection("propertyNumbers")
        .findOneAndUpdate(
          { _id: propertyNumberId },
          { $pull: { owners: { ownerId: ownerId } } }
        )
    } catch (error) {
      console.log(error)
    }
  }

  const updateOwner = async (owner: PropertyOwner) => {
    try {
      await db
        .collection("propertyOwners")
        .findOneAndUpdate(
          { _id: owner._id },
          { $set: owner },
          { returnNewDocument: true }
        )
    } catch (error) {
      console.log(error)
    }
  }

  const insertOwner = async (owner: PropertyOwner) => {
    try {
      const { insertedId } = await db
        .collection("propertyOwners")
        .insertOne(owner)
      return insertedId
    } catch (error) {
      console.log(error)
    }
  }

  const handleOwnerChange = async (
    propertyNumber: PropertyNumber | null,
    newOwner?: PropertyOwner | null,
    newShare?: number | null,
    oldOwner?: PropertyOwner | null
  ) => {
    if (!propertyNumber) return

    if (propertyNumber && !newOwner && oldOwner) {
      // disconnect owner from property number. Do not update owner
      removeOwnerOnPropertyNumber(propertyNumber._id, oldOwner._id)
      props.onChange()
      return
    }

    if (!newOwner) return

    newOwner.projectId = props.property.projectId

    let newOwnerExists = false
    try {
      newOwnerExists = await db
        .collection("propertyOwners")
        .findOne({ _id: newOwner._id })
    } catch (error) {
      console.log(error)
    }

    if (newOwnerExists) {
      // update existing owner
      await updateOwner(newOwner)
    } else {
      // create new owner
      const newOwnerId = await insertOwner(newOwner)
      newOwner._id = newOwnerId
    }

    const ownerIdsOnPropertyNumber =
      propertyNumber?.owners?.map((pn) => pn.ownerId) || []
    const newOwnerIsOnPropertyNumber = ownerIdsOnPropertyNumber.includes(
      newOwner._id
    )

    if (!newOwnerIsOnPropertyNumber) {
      if (oldOwner) {
        // replace owner on property number
        replaceOwnerOnPropertyNumber(
          propertyNumber._id,
          oldOwner._id,
          newOwner._id,
          newShare || null
        )
      } else {
        // add owner on property number
        addOwnerOnPropertyNumber(
          propertyNumber._id,
          newOwner._id,
          newShare || null
        )
      }
    }

    if (newShare) {
      updateShareOnPropertyNumber(
        propertyNumber._id,
        newOwner._id,
        newShare || null
      )
    }

    props.onChange()
  }

  const realmApp = useRealmApp()

  const sendSMS = async (owner: PropertyOwner) => {
    try {
      const propertyNumberLink = `${window.location.origin}/consent-form/${owner._id}`
      const msg = encodeURIComponent(
        `Hei, du har blitt lagt til i Algea Harvest sine systemer. Vi trenger samtykke til å høste tang på eiendommen din, samt å lagre informasjon om deg: ${propertyNumberLink}`
      )
      const from = encodeURIComponent(`Algea`)
      const to = owner.phoneNumber
      realmApp.currentUser?.functions
        .sendSveveSMS(from, to, msg)
        .then((result) => {
          if (result.status === "success") {
            const newSmsCount = (owner.smsCount ?? 0) + 1
            const newOwner = {
              ...owner,
              smsCount: newSmsCount,
            }
            updateOwner(newOwner)
            props.onChange()
          } else if (result.status === "error") {
            setSnackbar({ children: "Could not send SMS", severity: "error" })
          }
        })
        .catch((exception) => {
          console.log(`Error calling sendSveveSMS realm function: ${exception}`)
        })
    } catch (exception) {
      console.log(`Error sending sms ${exception}`)
    }
  }

  const { currentUser } = useRealmApp()
  const userRole = currentUser?.customData.role
  const showAddPropertyNumber =
    userRole === "admin" || userRole === "tanglandAdmin"
  const showEditPropertyNumber =
    userRole === "admin" || userRole === "tanglandAdmin"
  const showEditProperty = userRole === "admin" || userRole === "tanglandAdmin"
  const showEditOwner = userRole === "admin" || userRole === "tanglandAdmin"
  const showSendSms = userRole === "admin" || userRole === "tanglandAdmin"

  return (
    <div>
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight={700}>
              {props.property.name
                ? props.property.name
                : `Property ${props.index + 1}`}
              {` (${props.property.bagsHarvested ?? 0} bags)`}
            </Typography>

            {showEditProperty && (
              <IconButton
                aria-label="edit"
                onClick={() => {
                  props.onEditProperty(props.property)
                }}
                sx={{ ml: 1 }}
              >
                <Edit></Edit>
              </IconButton>
            )}
          </Box>

          {props.property.comment ? (
            <Box
              sx={{
                display: "flex",
                overflow: "hidden",
                textAlign: "left",
              }}
            >
              <Typography variant="body2" color={grey[600]} noWrap={false}>
                {props.property.comment}
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>

        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 900 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Property Number
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Owner Name
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Share
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Account Number
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Address
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Postal Code
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Postal City
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Email
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Phone Number
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Comment
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    Sent SMS Count
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25 }}>
                    GDPR & Harvest Consent
                  </TableCell>
                  <TableCell align="center" sx={{ p: 0.25, minWidth: 200 }}>
                    Edit/Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              {props.propertyNumbers.map((propertyNumber) => (
                <TableBody key={propertyNumber._id.toString()}>
                  {(!propertyNumber.owners ||
                    propertyNumber.owners?.length === 0) && (
                    <TableRow
                      sx={{
                        borderTop: "1px solid rgba(224, 224, 224, 1)",
                        borderBottom: "none",
                        "& td": { border: 0 },
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{ p: 0.25, minWidth: "80px" }}
                      >
                        {propertyNumber.propertyNumber}
                      </TableCell>{" "}
                      <TableCell
                        align="center"
                        sx={{ p: 0.25, maxWidth: "150px" }}
                      >
                        No owners added
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          <Stack direction="row" spacing={1}>
                            {showEditPropertyNumber && (
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setPropertyNumberToEdit(propertyNumber)
                                  setOpenEditPropertyNumberDialog(true)
                                }}
                              >
                                <Edit fontSize="small" />
                              </Button>
                            )}

                            {showEditOwner && (
                              <Button
                                onClick={() => {
                                  setPropertyNumberToEdit(propertyNumber)
                                  setOwnerToEdit(null)
                                  setOpenEditOwnerDialog(true)
                                }}
                              >
                                Add owner
                              </Button>
                            )}
                          </Stack>
                        }
                      </TableCell>
                    </TableRow>
                  )}

                  {propertyNumber.owners &&
                    propertyNumber.owners?.length > 0 && (
                      <TableRow
                        sx={{
                          borderTop: "1px solid rgba(224, 224, 224, 1)",
                          borderBottom: "none",
                          "& td": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{ p: 0.25, minWidth: "80px" }}
                        >
                          {propertyNumber.propertyNumber}
                        </TableCell>{" "}
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center" sx={{ p: 0.25 }}>
                          {
                            <Stack direction="row" spacing={1}>
                              {showEditPropertyNumber && (
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setPropertyNumberToEdit(propertyNumber)
                                    setOpenEditPropertyNumberDialog(true)
                                  }}
                                >
                                  <Edit fontSize="small" />
                                </Button>
                              )}

                              {showEditOwner && (
                                <Button
                                  onClick={() => {
                                    setPropertyNumberToEdit(propertyNumber)
                                    setOwnerToEdit(null)
                                    setOpenEditOwnerDialog(true)
                                  }}
                                >
                                  Add owner
                                </Button>
                              )}
                            </Stack>
                          }
                        </TableCell>
                      </TableRow>
                    )}

                  {propertyNumber.owners?.map((owner) => (
                    <TableRow
                      key={`${propertyNumber._id.toString()}-${owner.ownerId.toString()}`}
                      sx={{
                        border: "none",
                        "& td": { border: 0 },
                      }}
                    >
                      <TableCell></TableCell>
                      <TableCell
                        align="center"
                        sx={{ p: 0.25, maxWidth: "150px" }}
                      >
                        {
                          props.propertyOwners.find((po) =>
                            po._id.equals(owner.ownerId)
                          )?.name
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ p: 0.25, maxWidth: "150px" }}
                      >
                        {owner.share}
                        {owner.share && "%"}
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          props.propertyOwners.find((po) =>
                            po._id.equals(owner.ownerId)
                          )?.bankAccount
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          props.propertyOwners.find((po) =>
                            po._id.equals(owner.ownerId)
                          )?.address
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          props.propertyOwners.find((po) =>
                            po._id.equals(owner.ownerId)
                          )?.postalCode
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          props.propertyOwners.find((po) =>
                            po._id.equals(owner.ownerId)
                          )?.postalCity
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          props.propertyOwners.find((po) =>
                            po._id.equals(owner.ownerId)
                          )?.email
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          props.propertyOwners.find((po) =>
                            po._id.equals(owner.ownerId)
                          )?.phoneNumber
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ p: 0.25, maxWidth: "200px" }}
                      >
                        {
                          props.propertyOwners.find((po) =>
                            po._id.equals(owner.ownerId)
                          )?.comment
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {props.propertyOwners.find((po) =>
                          po._id.equals(owner.ownerId)
                        )?.smsCount ?? 0}
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          <GDPRIcon
                            propertyOwner={props.propertyOwners.find((po) =>
                              po._id.equals(owner.ownerId)
                            )}
                          />
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0.25 }}>
                        {
                          <Stack direction="row" spacing={1}>
                            {showEditOwner && (
                              <Button
                                onClick={() => {
                                  setPropertyNumberToEdit(propertyNumber)
                                  setOwnerToEdit(
                                    props.propertyOwners.find((po) =>
                                      po._id.equals(owner.ownerId)
                                    ) || null
                                  )
                                  setOpenEditOwnerDialog(true)
                                }}
                              >
                                <Edit fontSize="small" />
                              </Button>
                            )}

                            {showSendSms && (
                              <Button
                                onClick={() => {
                                  setOwnerToEdit(
                                    props.propertyOwners.find((po) =>
                                      po._id.equals(owner.ownerId)
                                    ) || null
                                  )
                                  setOpenSendOneSMSDialog(true)
                                }}
                              >
                                Send SMS
                              </Button>
                            )}
                          </Stack>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </Box>

        {showAddPropertyNumber && (
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                setOpenAddPropertyNumberDialog(true)
              }}
            >
              Add property number
            </Button>
          </Box>
        )}
      </Stack>

      <AddPropertyNumberDialog
        open={openAddPropertyNumberDialog}
        onClose={() => {
          setOpenAddPropertyNumberDialog(false)
        }}
        onSave={(propertyNumber) => {
          addPropertyNumber(propertyNumber)
          setOpenAddPropertyNumberDialog(false)
        }}
      />

      <EditPropertyNumberDialog
        open={openEditPropertyNumberDialog}
        propertyNumber={propertyNumberToEdit || undefined}
        onClose={() => {
          setOpenEditPropertyNumberDialog(false)
        }}
        onSave={(propertyNumber) => {
          updatePropertyNumber(propertyNumber)
          setOpenEditPropertyNumberDialog(false)
        }}
        onDelete={(propertyNumber) => {
          deletePropertyNumber(propertyNumber)
          setOpenEditPropertyNumberDialog(false)
        }}
      />

      <EditOwnerDialog
        open={openEditOwnerDialog}
        propertyNumber={propertyNumberToEdit || undefined}
        owner={ownerToEdit || undefined}
        ownersOnProject={props.propertyOwners}
        onClose={() => {
          setPropertyNumberToEdit(null)
          setOwnerToEdit(null)
          setOpenEditOwnerDialog(false)
        }}
        onSave={(owner, share) => {
          handleOwnerChange(propertyNumberToEdit, owner, share, ownerToEdit)
          setPropertyNumberToEdit(null)
          setOwnerToEdit(null)
          setOpenEditOwnerDialog(false)
        }}
        onDelete={() => {
          handleOwnerChange(propertyNumberToEdit, null, null, ownerToEdit)
          setPropertyNumberToEdit(null)
          setOwnerToEdit(null)
          setOpenEditOwnerDialog(false)
        }}
      />

      <SendOneSMSDialog
        open={openSendOneSMSDialog}
        owner={ownerToEdit || undefined}
        onSendSMS={(owner) => {
          sendSMS(owner)
          setOpenSendOneSMSDialog(false)
        }}
        onClose={() => {
          setOwnerToEdit(null)
          setOpenSendOneSMSDialog(false)
        }}
      />
    </div>
  )
}
