import { ArrowDownward, ArrowUpward, Download } from "@mui/icons-material"
import { Box, Button, Collapse, Stack, Typography } from "@mui/material"
import React, { useState, useEffect } from "react"
import InvoiceType from "../../../types/Invoice"
import { useMongoDB } from "../../MongoDB/MongoDB"
import Invoice from "../Invoice/Invoice"
import { useRealmApp } from "../../RealmApp/RealmApp"

interface Props {
  invoice: InvoiceType
}

export default function InvoiceRow(props: Props) {
  const [showInvoice, setShowInvoice] = useState<boolean>(false)

  const invoiceElement = React.useRef<any>()

  const { db } = useMongoDB()

  const [generateAndDownloadIsDisabled, setGenerateAndDownloadIsDisabled] =
    useState<boolean>(false)

  const generateInvoiceNumber = async () => {
    const baseNumber = 1000

    const invoices = await db.collection("invoices").find()
    const invoiceCount = invoices.length
    const nextInvoiceNumber = baseNumber + invoiceCount

    await db.collection("invoices").insertOne({
      invoiceNumber: nextInvoiceNumber,
      ownerId: props.invoice.owner._id,
    })

    return nextInvoiceNumber
  }

  const [currentInvoice, setCurrentInvoice] = useState<any>(null)
  const [showGenerateButton, setShowGenerateButton] = useState<boolean>(false)
  const checkIfInvoiceAlreadyExists = async () => {
    const invoice = await db
      .collection("invoices")
      .findOne({ ownerId: props.invoice.owner._id })
    if (invoice) {
      setShowGenerateButton(false)
      setCurrentInvoice(invoice)
    } else return setShowGenerateButton(true)
  }
  useEffect(() => {
    if (!db) return
    checkIfInvoiceAlreadyExists()
  }, [db])

  const generateAndDownloadInvoice = async () => {
    setGenerateAndDownloadIsDisabled(true)
    setTimeout(() => {
      setGenerateAndDownloadIsDisabled(false)
    }, 4000)

    const items: any[] = []
    props.invoice.properties.map((property) => {
      let propertyString = ""
      property.propertyNumbers.map((pn) => {
        propertyString += `${pn}, `
      })

      items.push({
        eiendom: propertyString,
        andel: property.propertyShare.toFixed(2),
        varenr: 1,
        tjeneste: "Råtang",
        tonn: property.tonnesHarvested.toFixed(2),
        pris: props.invoice.tonnePrice.toFixed(2),
        sum: property.totalPrice.toFixed(2),
      })
    })

    const invoiceNumber = await generateInvoiceNumber()

    const invoiceFields = {
      owner: props.invoice.owner.name,
      address: props.invoice.owner.address,
      postal_code: props.invoice.owner.postalCode,
      postal_city: props.invoice.owner.postalCity,
      email: props.invoice.owner.email,
      invoice_no: invoiceNumber,
      invoice_date:
        props.invoice.invoiceDate?.toLocaleDateString("nb-NO") || "",
      invoice_due_date:
        props.invoice.dueDate?.toLocaleDateString("nb-NO") || "",
      account_number: props.invoice.owner.bankAccount,
      phone: props.invoice.owner.phoneNumber,
      comment: props.invoice.owner.comment,
      sum: props.invoice.totalPriceWithoutVat.toFixed(2),
      mva: props.invoice.vat.toFixed(2),
      sum_total: props.invoice.totalPriceWithVat.toFixed(2),
      items: items,
    }

    setCurrentInvoice(invoiceFields)
    setShowGenerateButton(false)

    await db
      .collection("invoices")
      .updateOne({ invoiceNumber: invoiceNumber }, { $set: invoiceFields })

    downloadInvoice(invoiceFields)
  }

  const [downloadIsDisabled, setDownloadIsDisabled] = useState<boolean>(false)
  const downloadInvoice = async (invoiceFields: any) => {
    setDownloadIsDisabled(true)
    setTimeout(() => {
      setDownloadIsDisabled(false)
    }, 4000)

    const myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append("X-API-KEY", "d27aNTA4NzoyMDk4OjVTdG5kYm1ESThOeFJhb0c")
    myHeaders.append("Access-Control-Allow-Origin", "*")

    const apiString = JSON.stringify(invoiceFields)

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: apiString,
      redirect: "follow",
    }

    fetch(
      "https://rest.apitemplate.io/v2/create-pdf?template_id=49777b2388c9e38a",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result)
        const filename = JSON.parse(result).download_url
        console.log(filename)
        window.open(filename, "_blank")
      })
      .catch((error) => console.log("error", error))
  }

  const { currentUser } = useRealmApp()
  const userRole = currentUser?.customData.role
  const showGenerateInvoice =
    userRole === "admin" || userRole === "tanglandAdmin"

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
      }}
    >
      <Typography variant="h6">
        {props.invoice.owner.name || "Unknown owner"}
      </Typography>
      <Stack direction="column">
        <Stack direction="row">
          <Box width={120}>Address</Box>
          <Box>
            {props.invoice.owner.address}, {props.invoice.owner.postalCode}{" "}
            {props.invoice.owner.postalCity}
          </Box>
        </Stack>

        <Stack direction="row">
          <Box width={120}>Email</Box>
          <Box>{props.invoice.owner.email}</Box>
        </Stack>

        <Stack direction="row">
          <Box width={120}>Phone</Box>
          <Box>{props.invoice.owner.phoneNumber}</Box>
        </Stack>

        <Stack direction="row">
          <Box width={120}>Bank account</Box>
          <Box>{props.invoice.owner.bankAccount}</Box>
        </Stack>

        <Stack direction="row">
          <Box width={120}>Comment</Box>
          <Box>{props.invoice.owner.comment}</Box>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        gap={2}
        sx={{
          pt: 2,
        }}
      >
        <Button
          variant="contained"
          endIcon={showInvoice ? <ArrowUpward /> : <ArrowDownward />}
          onClick={() => {
            setShowInvoice(!showInvoice)
          }}
        >
          {showInvoice ? "Close Invoice Preview" : "Open Invoice Preview"}
        </Button>
        {showGenerateButton && showGenerateInvoice && (
          <Button
            variant="outlined"
            endIcon={<Download />}
            disabled={generateAndDownloadIsDisabled}
            onClick={generateAndDownloadInvoice}
          >
            Generate and Download invoice
          </Button>
        )}
        {!showGenerateButton && (
          <Button
            variant="outlined"
            endIcon={<Download />}
            disabled={downloadIsDisabled}
            onClick={() => {
              downloadInvoice(currentInvoice)
            }}
          >
            Download invoice
          </Button>
        )}
      </Stack>

      <Collapse in={showInvoice}>
        <Box>
          <Invoice invoice={props.invoice} />
        </Box>
      </Collapse>

      <Box
        sx={{
          position: "absolute",
          top: 10000,
        }}
      >
        <Box
          ref={invoiceElement}
          sx={{
            display: "none",
          }}
        >
          <Invoice invoice={props.invoice} />
        </Box>
      </Box>
    </Box>
  )
}
