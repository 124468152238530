import "./App.css"

import { RealmAppProvider } from "../RealmApp/RealmApp"
import realmConfig from "../../realm.json"
import LoginPage from "../LoginPage/LoginPage"
import { Routes, Route, Navigate } from "react-router-dom"
import UsersPage from "../UsersPage/UsersPage"
import MongoDB from "../MongoDB/MongoDB"
import ResetPasswordPage from "../ResetPasswordPage/ResetPasswordPage"
import PrivateRoute from "../PrivateRoute/PrivateRoute"
import ProjectsPage from "../ProjectsPage/ProjectsPage"
import ProjectPage from "../ProjectPage/ProjectPage"
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./CustomTheme"
import { SnackbarProvider } from "../Snackbar/SnackbarContext"
import GDPRConsentPage from "../GDPRConsentPage/GDPRConsentPage"

export default function AppWithRealm() {
  return (
    <SnackbarProvider>
      <RealmAppProvider appId={realmConfig.appId}>
        <MongoDB>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </MongoDB>
      </RealmAppProvider>
    </SnackbarProvider>
  )
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/consent-form/:propertyOwnerId"
          element={<GDPRConsentPage />}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetPassword" element={<ResetPasswordPage />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Navigate to="/projects" />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/project/:id/:tab" element={<ProjectPage />} />
        </Route>
      </Routes>
    </div>
  )
}
