import { Box, Button, Stack, TextField, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
import PropertyOwner from "../../../types/PropertyOwner"

interface Props {
  propertyOwner: PropertyOwner
  setChildButtonPress: Dispatch<SetStateAction<string>>
  setPropertyOwner: Dispatch<SetStateAction<PropertyOwner | null>>
  setPageStep: Dispatch<SetStateAction<number>>
  setTempGdprConsent: Dispatch<SetStateAction<boolean>>
}

export default function GDPRConsentForm(props: Props) {
  const onClickYes = async () => {
    props.setTempGdprConsent(true)
    props.setPageStep(2)
  }

  const onClickNo = async () => {
    props.setTempGdprConsent(false)
    props.setPageStep(3)
  }

  let bankAccountToBeEdited = props.propertyOwner?.bankAccount || ""

  const handleFormOwnerChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setPropertyOwner({
      ...props.propertyOwner,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Box
      sx={{
        background: "#fff",
        py: 1,
        m: 1,
        p: 2,
        borderRadius: 3,
        boxShadow: 1,
        width: "80%",
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{}}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box
          component="img"
          sx={{
            height: 130,
            width: 100,
          }}
          src="/logo.svg"
        />
        <Typography variant="h6" align="center">
          Samtykker du til at Algea Harvest høster tang på eiendommen din?
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ mt: 1 }}
          color="grey.500"
          align="center"
        >
          Dette inkluderer navn, telefonnummer, addresse, og kontonummer.
          Samtykke trengs i forbindelse med EUs GDPR-regler. Vi trenger
          kontonummeret ditt for å betale deg.
        </Typography>

        <TextField
          label="Kontonummer"
          name="bankAccount"
          sx={{ width: "100%", mt: 2 }}
          value={props.propertyOwner?.bankAccount || ""}
          onChange={handleFormOwnerChange}
        ></TextField>

        <Stack
          direction="row"
          gap={4}
          sx={{
            pt: 4,
          }}
        >
          <Button
            size="large"
            variant="outlined"
            onClick={onClickNo}
            sx={{ borderRadius: 3 }}
          >
            Nei
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={onClickYes}
            disabled={bankAccountToBeEdited === ""}
            sx={{
              borderRadius: 3,
            }}
          >
            Ja
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
