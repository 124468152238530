import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material"
import grey from "@mui/material/colors/grey"
import React, { useEffect, useState } from "react"
import FeatureWithId from "../../../types/FeatureWithId"
import MapIcon from "@mui/icons-material/Map"

interface EditAreaDialogProps {
  selectedFeature: FeatureWithId | null
  onHarvestIsAllowedChange: (
    feature: FeatureWithId,
    harvestIsAllowed: string
  ) => void
}

export default function EditAreaDialog(props: EditAreaDialogProps) {
  const handleHarvestIsAllowedChange = async (
    event: SelectChangeEvent<string>
  ) => {
    if (!props.selectedFeature) return
    props.onHarvestIsAllowedChange(props.selectedFeature, event.target.value)
    setHarvestIsAllowedValue(event.target.value)
  }

  const [harvestIsAllowedValue, setHarvestIsAllowedValue] =
    useState<string>("unknown")

  useEffect(() => {
    if (props.selectedFeature?.properties?.harvestIsAllowed === true) {
      setHarvestIsAllowedValue("yes")
    } else if (props.selectedFeature?.properties?.harvestIsAllowed === false) {
      setHarvestIsAllowedValue("no")
    } else {
      setHarvestIsAllowedValue("unknown")
    }
  }, [props.selectedFeature])

  return (
    <div>
      <Stack
        spacing={0}
        sx={{
          maxWidth: 275,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          height: "170px",
        }}
      >
        <Typography variant="h6" component="h2">
          Edit area
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {props.selectedFeature ? (
            <div>
              <Typography variant="caption" fontWeight="400" color={grey[600]}>
                Is it allowed to harvest on the current map selection?
              </Typography>

              <Box sx={{ pt: 2 }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="form-propertyId">
                    Allowed to harvest?
                  </InputLabel>
                  <Select
                    labelId="form-harvestIsAllowed"
                    label="Allowed to harvest?"
                    name="harvestIsAllowed"
                    value={harvestIsAllowedValue}
                    onChange={(e) => {
                      handleHarvestIsAllowedChange(
                        e as SelectChangeEvent<string>
                      )
                    }}
                  >
                    <MenuItem value="unknown">Not decided</MenuItem>
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          ) : (
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MapIcon color="action" />
                <Typography
                  variant="caption"
                  fontWeight="400"
                  color={grey[600]}
                >
                  Click on a area in the map
                </Typography>
              </Box>
            </div>
          )}
        </Box>
      </Stack>
    </div>
  )
}
