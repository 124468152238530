import React, { MouseEventHandler, PropsWithChildren } from "react"
import {
  Button,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  Breakpoint,
  SxProps,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AppBar from "@mui/material/AppBar"

interface DialogWithActionProps extends PropsWithChildren {
  show: boolean
  actionTitle: string
  actionDescription?: string
  textOptionA?: string
  actionOptionA?: MouseEventHandler<HTMLButtonElement>
  disableOptionA?: boolean
  textOptionB?: string
  actionOptionB?: MouseEventHandler<HTMLButtonElement>
  disableOptionB?: boolean
  fullScreen?: boolean
  maxWidth?: false | Breakpoint
  dialogSx?: SxProps
  dialogContentSx?: SxProps
  exitAction?: MouseEventHandler<HTMLButtonElement>
  bigButtons?: boolean
}

export default function DialogWithAction(props: DialogWithActionProps) {
  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    noButtonRef.current?.focus()
  }

  const noButtonRef = React.useRef<HTMLButtonElement>(null)

  return (
    <Dialog
      maxWidth={props.maxWidth === undefined ? "xs" : props.maxWidth}
      TransitionProps={{ onEntered: handleEntered }}
      open={props.show}
      fullScreen={props.fullScreen !== undefined ? props.fullScreen : false}
      sx={props.dialogSx}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          {props.exitAction && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.exitAction}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
          <DialogTitle>{props.actionTitle}</DialogTitle>
        </Toolbar>
      </AppBar>
      <DialogContent sx={props.dialogContentSx}>
        <DialogContentText>{props.actionDescription}</DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        {props.actionOptionA !== undefined &&
          props.textOptionA !== undefined && (
            <Button
              ref={noButtonRef}
              variant={"outlined"}
              onClick={props.actionOptionA}
              disabled={props.disableOptionA}
              sx={{
                borderRadius: 4,
                ...(props.bigButtons && {
                  width: 150,
                  height: 75,
                }),
              }}
            >
              <Typography variant={props.bigButtons ? "h5" : "button"}>
                {props.textOptionA}
              </Typography>
            </Button>
          )}
        {props.actionOptionB !== undefined &&
          props.textOptionB !== undefined && (
            <Button
              onClick={props.actionOptionB}
              variant={"contained"}
              disabled={props.disableOptionB}
              sx={{
                borderRadius: 4,
                ...(props.bigButtons && {
                  width: 150,
                  height: 75,
                }),
              }}
            >
              <Typography variant={props.bigButtons ? "h5" : "button"}>
                {props.textOptionB}
              </Typography>
            </Button>
          )}
      </DialogActions>
    </Dialog>
  )
}
