import { useEffect, useRef, useState } from "react"
import mapboxgl, { LngLatBounds } from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import "./PrintableMap.css"
import { Box } from "@mui/system"

import MapboxDraw from "@mapbox/mapbox-gl-draw"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import { useTheme } from "@mui/material/styles"
import Property from "../../../types/Property"

interface Props {
  properties: Property[]
  lng: number
  lat: number
  zoom: number
  bbox?: LngLatBounds
  mapHeight: number
  mapWidth: number
}

export default function PrintableMap(props: Props) {
  const theme = useTheme()

  mapboxgl.accessToken =
    "pk.eyJ1IjoibGFyc2h2aWRzdGVuIiwiYSI6ImNranlkb21vczA5OGUycW1xamZ6bXJnbTIifQ.I5-Nc4Mzi9sUDusCg91ibA"
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const [lng, setLng] = useState(props.lng)
  const [lat, setLat] = useState(props.lat)
  const [zoom, setZoom] = useState(props.zoom)
  const [bbox, setBbox] = useState(props.bbox)
  const [properties, setProperties] = useState<Property[]>(props.properties)

  const [mapHeight, setMapHeight] = useState(props.mapHeight)
  const [mapWidth, setMapWidth] = useState(props.mapWidth)

  useEffect(() => {
    setLng(props.lng)
  }, [props.lng])
  useEffect(() => {
    setLat(props.lat)
  }, [props.lat])
  useEffect(() => {
    setZoom(props.zoom)
  }, [props.zoom])
  useEffect(() => {
    console.log('pm bbox')
    console.log(props.bbox?.toArray())

    if (!props.bbox) return
    map.current?.fitBounds(props.bbox)
  }, [props.bbox])
  useEffect(() => {
    setProperties(props.properties)
  }, [props.properties])

  useEffect(() => {
    const MAX_HEIGHT_MM = 210; // Maximum height in millimeters
    const MAX_WIDTH_MM = 297; // Maximum width in millimeters

    // Calculate the aspect ratio
    const aspectRatio = props.mapWidth / props.mapHeight;

    // Calculate scaling factors for height and width
    const heightScaleFactor = MAX_HEIGHT_MM / props.mapHeight;
    const widthScaleFactor = MAX_WIDTH_MM / props.mapWidth;

    // Choose the smaller scaling factor to ensure the image fits within the bounds
    const scaleFactor = Math.min(heightScaleFactor, widthScaleFactor);

    // Calculate the new dimensions while maintaining the aspect ratio
    const newHeight = props.mapHeight * scaleFactor;
    const newWidth = props.mapWidth * scaleFactor;

    // Update the state with the new dimensions
    setMapHeight(newHeight);
    setMapWidth(newWidth);

    console.log(newHeight, newWidth)
  }, [props.mapHeight, props.mapWidth])

  const renderProperties = async (properties: Property[]) => {
    properties.forEach((property: Property, index) => {
      if (!property.geojson || !Array.isArray(property.geojson)) return

      property.geojson?.forEach((feature, index) => {
        draw?.current.add(feature)

        draw?.current.setFeatureProperty(
          String(feature.id),
          "propertyOfFeature",
          property.name || "Property " + index
        )
        draw?.current.setFeatureProperty(
          String(feature.id),
          "isSelected",
          false
        )

        // Make sure Draw can read the harvestIsAllowed property so that it applies the correct styling
        if (feature?.properties?.harvestIsAllowed === "no") {
          draw?.current.setFeatureProperty(
            String(feature.id),
            "harvestIsAllowed",
            false
          )
        } else if (feature?.properties?.harvestIsAllowed === "yes") {
          draw?.current.setFeatureProperty(
            String(feature.id),
            "harvestIsAllowed",
            true
          )
        }
      })
    })
  }

  const drawColors = {
    selected: {
      harvestIsAllowed: {
        fill: theme.palette.success.dark,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.dark,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
    active: {
      harvestIsAllowed: {
        fill: theme.palette.success.light,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.light,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
    inactive: {
      harvestIsAllowed: {
        fill: theme.palette.success.light,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.light,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
  }

  const mapboxDrawStyles = [
    {
      id: "gl-draw-polygon-fill-inactive",
      type: "fill",
      filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
      paint: {
        "fill-color": [
          "case",
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], false],
          ],
          drawColors.selected.harvestNotAllowed.fill,
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], true],
          ],
          drawColors.selected.harvestIsAllowed.fill,
          ["==", ["get", "user_isSelected"], true],
          drawColors.selected.unknownIfAllowed.fill,

          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.inactive.harvestNotAllowed.fill,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.inactive.harvestIsAllowed.fill,

          // otherwise
          drawColors.inactive.unknownIfAllowed.fill,
        ],
        "fill-opacity": [
          "case",
          ["==", ["get", "user_isSelected"], true],
          0.1,
          // not selected
          0.0,
        ],
      },
    },
    {
      id: "gl-draw-polygon-stroke-inactive",
      type: "line",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Polygon"],
        ["!=", "mode", "static"],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], false],
          ],
          drawColors.selected.harvestNotAllowed.outline,
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], true],
          ],
          drawColors.selected.harvestIsAllowed.outline,
          ["==", ["get", "user_isSelected"], true],
          drawColors.selected.unknownIfAllowed.outline,

          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.inactive.harvestNotAllowed.outline,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.inactive.harvestIsAllowed.outline,

          // otherwise
          drawColors.inactive.unknownIfAllowed.outline,
        ],
        "line-width": [
          "case",
          ["==", ["get", "user_isSelected"], true],
          5,
          // not selected
          2,
        ],
      },
    },
    {
      id: "gl-draw-line-active",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], false],
          ],
          drawColors.selected.harvestNotAllowed.outline,
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], true],
          ],
          drawColors.selected.harvestIsAllowed.outline,
          ["==", ["get", "user_isSelected"], true],
          drawColors.selected.unknownIfAllowed.outline,

          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.active.harvestNotAllowed.outline,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.active.harvestIsAllowed.outline,

          // otherwise
          drawColors.active.unknownIfAllowed.outline,
        ],
        "line-dasharray": [0.2, 2],
        "line-width": [
          "case",
          ["==", ["get", "user_isSelected"], true],
          5,
          // not selected
          2,
        ],
      },
    },

    {
      id: "labels",
      type: "symbol",
      layout: {
        "text-field": ["get", "user_propertyOfFeature"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-anchor": "top",
      },
    },
  ]

  const draw = useRef(
    new MapboxDraw({
      userProperties: true,
      styles: mapboxDrawStyles,
      controls: {
        point: false,
        line_string: false,
        polygon: false,
        trash: false,
        combine_features: false,
        uncombine_features: false,
      },
    })
  )

  // Set up the map once
  useEffect(() => {
    if (map.current) return
    if (!properties.length) return

    map.current = new mapboxgl.Map({
      container: mapContainer.current || "",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    })

    map.current?.on("load", async () => {
      map.current?.addSource("norgeskart-topo4", {
        type: "raster",
        tiles: [
          "https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=toporaster4&zoom={z}&x={x}&y={y}",
        ],
        tileSize: 128,
      })

      map.current?.addLayer({
        id: "norgeskart-topo4",
        type: "raster",
        source: "norgeskart-topo4",
        paint: {},
      })

      map.current?.addControl(draw.current, "top-left")

      await renderProperties(properties)

      setTimeout(() => {
        //window.print()
      }, 3000)
    })
  }, [properties, lat, lng, zoom])

  return (
    <Box
      sx={{
        height: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            height: `${mapHeight}mm`, // "297mm",
            width: `${mapWidth}mm`, // "420mm",
          }}
        >
          <div ref={mapContainer} className="map-container" />
        </Box>
      </Box>
    </Box>
  )
}
