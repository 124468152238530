import { FormatListBulleted, Person, Map } from "@mui/icons-material"
import { Box, List, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import LogoutButton from "../LogoutButton/LogoutButton"
import SideNavigationListItem from "./SideNavigationListItem/SideNavigationListItem"
import { useRealmApp } from "../RealmApp/RealmApp"

interface Props {
  onClick: Function
}

export default function SideNavigation(props: Props) {
  const navItems = [
    {
      title: "Projects",
      path: "/projects",
      icon: <FormatListBulleted />,
    },
  ]

  const { currentUser } = useRealmApp()
  const userRole = currentUser?.customData.role
  if (userRole === "admin") {
    navItems.push({
      title: "Users",
      path: "/users",
      icon: <Person />,
    })
  }

  const navigate = useNavigate()

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    path: string
  ) => {
    props.onClick()
    navigate(path)
  }
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: 100,
              pl: "16px",
              bgcolor: "primary.light",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 85,
                  width: 66,
                  mr: "16px",
                }}
                src="/logo.svg"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6" fontWeight={700}>
                  Map Admin
                </Typography>
                <Typography variant="overline" fontWeight={600} lineHeight="1">
                  BY ALGEA HARVEST
                </Typography>
              </Box>
            </Box>
          </Box>
          <List>
            {navItems.map((item, index) => (
              <SideNavigationListItem
                key={index}
                title={item.title}
                path={item.path}
                icon={item.icon}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                  handleListItemClick(e, index, item.path)
                }
              />
            ))}
          </List>
        </Box>
        <Box
          sx={{
            mx: "auto",
            pb: 4,
          }}
        >
          <LogoutButton />
        </Box>
      </Box>
    </div>
  )
}
