import HarvesterMap from "./HarvesterMap/HarvesterMap"
import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import ConfirmBagDropDialog from "./ConfirmBagDropDialog/ConfirmBagDropDialog"
import { BSON } from "realm-web"
import BagDrop from "../../types/BagDrop"
import BagDropActions from "./BagDropActions/BagDropActions"
import { useRealmApp } from "../RealmApp/RealmApp"
import { useMongoDB } from "../MongoDB/MongoDB"
import StatsCard from "./StatsCard/StatsCard"
import { AccessTime } from "@mui/icons-material"
import UndoBagDropDialog from "./UndoBagDropDialog/UndoBagDropDialog"
import { useSnackbar } from "../Snackbar/SnackbarContext"

interface Props {
  projectId?: string
}

export default function HarvesterView(props: Props) {
  const setSnackbar = useSnackbar()
  const { currentUser } = useRealmApp()

  const [projectId, _setProjectId] = useState<BSON.ObjectId | undefined>(
    new BSON.ObjectId(props.projectId)
  )

  const { db } = useMongoDB()
  const [openUndoBagDropDialog, setOpenUndoBagDropDialog] =
    useState<boolean>(false)
  const [position, setPosition] = useState<GeolocationPosition | null>(null)

  const [bagDrops, setBagDrops] = useState<BagDrop[] | null>(null)

  const [numBagDropsInSession, setNumBagDropsInSession] = useState<number>(0)

  const handleConfirmBagDrop = async () => {
    try {
      if (!db) throw Error("No database connection")
      if (!currentUser) throw Error("No current user")
      if (!position) throw Error("No position")
      if (!projectId) throw Error("No project id")

      const drop: BagDrop = {
        _id: new BSON.ObjectId(),
        dropTime: new Date(),
        projectId: projectId,
        droppedByUserId: currentUser.id,
        geojson: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude],
          },
        },
      }
      setBagDrops([...(bagDrops || []), drop])
      await db.collection("bagDrops").insertOne(drop)
      setNumBagDropsInSession(numBagDropsInSession + 1)
    } catch (e) {
      setSnackbar({ children: "Error saving bag drop", severity: "error" })
    }
  }

  const handleGeolocate = (pos: GeolocationPosition) => {
    setPosition(pos)
  }

  useEffect(() => {
    const getBagDrops = async () => {
      if (!db) return
      const bagDrops = await db
        .collection("bagDrops")
        .find({ projectId: projectId })

      if (bagDrops) setBagDrops(bagDrops)
    }
    getBagDrops()
  }, [db, projectId])

  const getBagsDroppedToday = () => {
    if (!bagDrops) return 0
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return bagDrops.filter((drop) => drop.dropTime > today).length
  }

  const getTotalBagsDropped = () => {
    if (!bagDrops) return 0
    return bagDrops.length
  }

  const getFirstBagDrop = () => {
    if (!bagDrops || bagDrops.length === 0) return null
    return bagDrops.reduce((acc, curr) =>
      acc.dropTime < curr.dropTime ? acc : curr
    )
  }

  const handleUndoLastBagDrop = async () => {
    try {
      if (!db) throw Error("No database connection")
      if (!projectId) throw Error("No project id")
      if (!bagDrops) throw Error("No bag drops")
      if (numBagDropsInSession === 0)
        throw Error("No bag drops in current session")

      const lastBagDrop = bagDrops[bagDrops.length - 1]

      await db
        .collection("bagDrops")
        .deleteOne({ _id: lastBagDrop._id, projectId: projectId })
      setBagDrops(bagDrops.filter((drop) => !drop._id.equals(lastBagDrop._id)))
      setNumBagDropsInSession(numBagDropsInSession - 1)
      setOpenUndoBagDropDialog(false)
    } catch (error) {
      setSnackbar({ children: "Error undoing bag drop", severity: "error" })
    }
  }

  const firstBagDrop = getFirstBagDrop()

  const userRole = currentUser?.customData.role
  const showDropBagActions =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "hoster"

  return (
    <Box
      sx={{
        height: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HarvesterMap
        projectId={projectId ?? null}
        onGeolocate={handleGeolocate}
        bagDrops={bagDrops}
      />
      {showDropBagActions && (
        <BagDropActions
          onDropBag={handleConfirmBagDrop}
          onUndo={() => setOpenUndoBagDropDialog(true)}
          dropBagDisabled={position === null}
          undoDisabled={numBagDropsInSession === 0}
        />
      )}
      <StatsCard
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          right: 0,
          top: 0,
          mt: 10,
          mr: 4,
          p: 2,
          width: 230,
          height: 60,
        }}
        title={"Bags dropped today"}
        value={getBagsDroppedToday()}
        description={
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            alignItems={"center"}
          >
            <AccessTime fontSize="small" />
            <Typography variant="body1">
              {new Date().toLocaleDateString("no-NO")}
            </Typography>
          </Box>
        }
      />
      <StatsCard
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          right: 280,
          top: 0,
          mt: 10,
          mr: 4,
          p: 2,
          width: 230,
          height: 60,
        }}
        title={"Total bags dropped"}
        value={getTotalBagsDropped()}
        description={
          firstBagDrop && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={1}
              alignItems={"center"}
            >
              <AccessTime fontSize="small" />
              <Typography variant="body1">
                {`From ${firstBagDrop?.dropTime.toLocaleDateString("no-NO")}`}
              </Typography>
            </Box>
          )
        }
      />
      {bagDrops && numBagDropsInSession > 0 && (
        <UndoBagDropDialog
          show={openUndoBagDropDialog}
          onConfirm={handleUndoLastBagDrop}
          onCancel={() => setOpenUndoBagDropDialog(false)}
          bagDrop={bagDrops?.[bagDrops.length - 1]}
        />
      )}
    </Box>
  )
}
