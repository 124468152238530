import React, { useEffect, useState } from "react"
import { Snackbar as MuiSnackbar, Alert, AlertColor } from "@mui/material"

export interface SnackbarProps {
  children?: React.ReactNode
  severity?: AlertColor
}

export function Snackbar(props: SnackbarProps | null) {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)

  useEffect(() => {
    if (props && props.children) {
      setSnackbarOpen(true)
    }
  }, [props])

  if (!props || !props.children) return null
  return (
    <MuiSnackbar
      open={snackbarOpen}
      onClose={() => {
        setSnackbarOpen(false)
      }}
      autoHideDuration={6000}
    >
      <Alert
        {...props}
        onClose={() => {
          setSnackbarOpen(false)
        }}
      />
    </MuiSnackbar>
  )
}
