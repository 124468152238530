import { Close } from "@mui/icons-material"
import {
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import PropertyOwner from "../../../types/PropertyOwner"
import PropertyNumber from "../../../types/PropertyNumber"
import { useEffect, useState } from "react"
import { BSON } from "realm-web"

enum GDPRConsentFormValue {
  TRUE = "gdpr-consent-true",
  FALSE = "gdpr-consent-false",
  UNDEFINED = "gdpr-consent-undefined",
}

interface Props {
  ownersOnProject: PropertyOwner[]
  owner?: PropertyOwner
  propertyNumber?: PropertyNumber
  open: boolean
  onClose: () => void
  onSave: (owner: PropertyOwner | null, share: number) => void
  onDelete: () => void
}

const emptyOwner: PropertyOwner = {
  _id: new BSON.ObjectID(),
  projectId: new BSON.ObjectID(),
}

export function EditOwnerDialog(props: Props) {
  const [editedOwner, setEditedOwner] = useState<PropertyOwner | null>(
    props.owner || null
  )

  const [selectedOwnerIdString, setSelectedOwnerIdString] = useState<string>(
    props.owner?._id.toString() || "no-owner"
  )

  useEffect(() => {
    setEditedOwner(props.owner || null)
    setSelectedOwnerIdString(props.owner?._id.toString() || "no-owner")
  }, [props.owner])

  const handleFormOwnerChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!editedOwner) return
    setEditedOwner({ ...editedOwner, [event.target.name]: event.target.value })
  }

  const [editedShare, setEditedShare] = useState<number>(0)
  useEffect(() => {
    if (!editedOwner) return

    if (props.propertyNumber?.owners) {
      const ownerOnProperty = props.propertyNumber?.owners.find((oopn) => {
        return oopn.ownerId.toString() === editedOwner?._id.toString()
      })
      if (ownerOnProperty) {
        setEditedShare(ownerOnProperty.share)
        return
      } else {
        setEditedShare(0)
        return
      }
    }

    if (
      !props.propertyNumber?.owners ||
      props.propertyNumber?.owners.length === 0
    ) {
      setEditedShare(100)
      return
    }
  }, [props.propertyNumber, editedOwner])

  const [maxShare, setMaxShare] = useState<number>(100)
  useEffect(() => {
    if (!props.propertyNumber?.owners) return

    let maxShare = 100
    props.propertyNumber?.owners.forEach((oopn) => {
      if (oopn.ownerId.toString() === editedOwner?._id.toString()) return
      maxShare -= oopn.share
    })
    if (maxShare < 0) maxShare = 0
    setMaxShare(maxShare)
  }, [props.propertyNumber, editedOwner])

  const handleShareChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!editedOwner) return
    setEditedShare(Math.min(maxShare, parseInt(event.target.value)))
  }

  useEffect(() => {
    if (selectedOwnerIdString === "new-owner") {
      setEditedOwner({ ...emptyOwner, _id: new BSON.ObjectID() })
    } else if (selectedOwnerIdString === "no-owner") {
      setEditedOwner(null)
    } else {
      const selectedOwner = props.ownersOnProject.find((owner) => {
        return owner?._id.toString() === selectedOwnerIdString
      })
      if (selectedOwner) {
        setEditedOwner(selectedOwner)
      }
    }
  }, [selectedOwnerIdString])

  const handleFormOwnerGDPRConsentChange = async (
    event: SelectChangeEvent<GDPRConsentFormValue>
  ) => {
    if (!editedOwner) return
    if (event.target.value === GDPRConsentFormValue.TRUE) {
      setEditedOwner({ ...editedOwner, gdprConsent: true })
    } else if (event.target.value === GDPRConsentFormValue.FALSE) {
      setEditedOwner({ ...editedOwner, gdprConsent: false })
    } else {
      setEditedOwner({ ...editedOwner, gdprConsent: undefined })
    }
  }

  const handleOwnerSelectChange = async (event: SelectChangeEvent<string>) => {
    setSelectedOwnerIdString(event.target.value)
  }

  const getOwnerGDPRConsentFormValue = (): GDPRConsentFormValue => {
    if (editedOwner?.gdprConsent === true) {
      return GDPRConsentFormValue.TRUE
    } else if (editedOwner?.gdprConsent === false) {
      return GDPRConsentFormValue.FALSE
    }
    return GDPRConsentFormValue.UNDEFINED
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        setEditedOwner(null)
        setSelectedOwnerIdString("no-owner")
        props.onClose()
      }}
    >
      <Stack sx={{ py: 2, px: 4 }} spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {props.owner ? "Edit owner " : "Add owner "}
            on property number {props.propertyNumber?.propertyNumber}
          </Typography>
          <IconButton
            onClick={() => {
              setEditedOwner(null)
              setSelectedOwnerIdString("no-owner")
              props.onClose()
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box>
          <Stack spacing={2} sx={{ pt: 2 }}>
            <Typography variant="body1">
              Choose an owner for this property number
            </Typography>

            <Box>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="form-owner">Property owner</InputLabel>
                <Select
                  labelId="form-owner"
                  label="Property owner"
                  name="form-owner"
                  value={selectedOwnerIdString}
                  onChange={(e) => {
                    handleOwnerSelectChange(e as SelectChangeEvent<string>)
                  }}
                >
                  <MenuItem value="new-owner">Create new owner</MenuItem>
                  <MenuItem disabled value="no-owner">
                    Unknown owner
                  </MenuItem>
                  {props.ownersOnProject.map((owner) => {
                    return (
                      <MenuItem
                        key={owner?._id.toString()}
                        value={owner?._id.toString()}
                        disabled={
                          props.propertyNumber?.owners?.find(
                            (oopn) =>
                              oopn.ownerId.toString() === owner?._id.toString()
                          ) !== undefined &&
                          owner?._id.toString() !== props.owner?._id.toString()
                        }
                      >
                        {owner?.name || "Owner without name"}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>

            {selectedOwnerIdString !== "no-owner" && (
              <>
                <Typography variant="body1">Edit the selected Owner</Typography>

                <Box
                  component="form"
                  sx={{ width: 250, mx: "auto", rowGap: 1 }}
                  display="grid"
                >
                  <Box>
                    <TextField
                      label="Name"
                      name="name"
                      sx={{ width: "100%" }}
                      value={editedOwner?.name || ""}
                      onChange={handleFormOwnerChange}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      label="Share"
                      name="share"
                      sx={{ width: "100%" }}
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: maxShare || 100,
                        },
                      }}
                      value={editedShare || 0}
                      onChange={handleShareChange}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      label="Account number"
                      name="bankAccount"
                      sx={{ width: "100%" }}
                      value={editedOwner?.bankAccount || ""}
                      onChange={handleFormOwnerChange}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      label="Address"
                      name="address"
                      sx={{ width: "100%" }}
                      value={editedOwner?.address || ""}
                      onChange={handleFormOwnerChange}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      label="Postal Code"
                      name="postalCode"
                      sx={{ width: "100%" }}
                      value={editedOwner?.postalCode ?? ""}
                      onChange={handleFormOwnerChange}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      label="Postal City"
                      name="postalCity"
                      sx={{ width: "100%" }}
                      value={editedOwner?.postalCity ?? ""}
                      onChange={handleFormOwnerChange}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      label="Email"
                      name="email"
                      sx={{ width: "100%" }}
                      value={editedOwner?.email || ""}
                      onChange={handleFormOwnerChange}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      sx={{ width: "100%" }}
                      value={editedOwner?.phoneNumber ?? ""}
                      onChange={handleFormOwnerChange}
                    ></TextField>
                  </Box>
                  <Box>
                    <TextField
                      label="Comment"
                      name="comment"
                      multiline
                      sx={{ width: "100%" }}
                      value={editedOwner?.comment || ""}
                      onChange={handleFormOwnerChange}
                    ></TextField>
                  </Box>
                  <Typography variant="body1">
                    GDPR & Harvest consent
                  </Typography>
                  <Box>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        labelId="form-owner-gdpr-consent"
                        name="form-owner-gdpr-consent"
                        value={getOwnerGDPRConsentFormValue()}
                        onChange={(e) => {
                          handleFormOwnerGDPRConsentChange(
                            e as SelectChangeEvent<GDPRConsentFormValue>
                          )
                        }}
                      >
                        <MenuItem value={GDPRConsentFormValue.TRUE}>
                          Yes
                        </MenuItem>
                        <MenuItem value={GDPRConsentFormValue.FALSE}>
                          No
                        </MenuItem>
                        <MenuItem value={GDPRConsentFormValue.UNDEFINED}>
                          Unknown
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </>
            )}
          </Stack>
        </Box>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              props.onDelete()
            }}
            disabled={!props.owner}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.onSave(editedOwner, editedShare)
              setSelectedOwnerIdString("no-owner")
              setEditedOwner(null)
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
