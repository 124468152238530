import { Block, Check, QuestionMark } from "@mui/icons-material"
import PropertyOwner from "../../../../types/PropertyOwner"

interface Props {
  propertyOwner: PropertyOwner | undefined
}

export default function GDPRIcon(props: Props) {
  const { propertyOwner } = props

  if (propertyOwner?.gdprConsent === true) {
    return <Check color="success" />
  } else if (propertyOwner?.gdprConsent === false) {
    return <Block color="error" />
  }
  return <QuestionMark />
}
