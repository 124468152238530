import Header from "../Header/Header"
import {
  AlertProps,
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  Fab,
  Stack,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useEffect, useState } from "react"
import React from "react"
import SnackbarWrapper from "../SnackbarWrapper/SnackbarWrapper"
import { useMongoDB } from "../MongoDB/MongoDB"
import ProjectCard from "./ProjectCard/ProjectCard"
import EditProjectDialog from "./EditProjectDialog/EditProjectDialog"
import Project from "../../types/Project"
import NewProjectDialog from "./NewProjectDialog/NewProjectDialog"
import { useRealmApp } from "../RealmApp/RealmApp"

export default function ProjectsPage() {
  const [loadingScreenOpen, setLoadingScreenOpen] = useState<boolean>(true)
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)
  const { db } = useMongoDB()
  const { currentUser } = useRealmApp()
  const userRole = currentUser?.customData.role
  const showCreateEditProject =
    userRole === "admin" || userRole === "tanglandAdmin"

  const [projects, setProjects] = useState<Project[]>([])

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [projectToEdit, setProjectToEdit] = useState<Project | null>(null)

  const fetchProjects = async () => {
    setLoadingScreenOpen(true)
    let fetchedProjectsTyped: Project[] = []
    try {
      const fetchedProjects = await db.collection("projects").find()
      fetchedProjects.forEach(async (p: any) => {
        fetchedProjectsTyped.push({
          _id: p._id,
          name: p.name,
          tonnesHarvested: p.tonnesHarvested,
          tonnePrice: p.tonnePrice,
          status: p.status,
          createdDate: p.createdDate,
        })
      })
    } catch (error) {
      if (error instanceof Error) {
        setSnackbar({
          children:
            "Error fetching projects. Contact system admin to ensure you have the correct permissions.",
          severity: "error",
        })
      }
    }

    let index = 0

    fetchedProjectsTyped = fetchedProjectsTyped.sort(
      (a: Project, b: Project) => {
        if (a.createdDate == undefined) {
          return 1
        }

        if (b.createdDate == undefined) {
          return -1
        }
        return b.createdDate.valueOf() - a.createdDate.valueOf()
      }
    )
    // !!a.createdDate ? a.createdDate.getTime() > b.createdDate.getTime() : true

    for (const project of fetchedProjectsTyped) {
      try {
        const propertyCount = await db
          .collection("properties")
          .count({ projectId: project._id?.toString() })

        const p = { ...project, propertyCount: propertyCount }
        fetchedProjectsTyped[index] = p
      } catch (error) {
        if (error instanceof Error) {
          setSnackbar({ children: error.message, severity: "error" })
        }
      }

      index++
    }
    setProjects(fetchedProjectsTyped)
    setLoadingScreenOpen(false)
  }

  useEffect(() => {
    if (db) {
      fetchProjects()
    }
  }, [db])

  const updateProject = async (project: Project) => {
    if (userRole !== "admin") {
      delete project.tonnesHarvested
      delete project.tonnePrice
    }
    try {
      const ret = await db
        .collection("projects")
        .findOneAndUpdate(
          { _id: project._id },
          { $set: project },
          { returnNewDocument: true }
        )
    } catch (error) {
      console.log(error)
      return
    }

    setEditModalOpen(false)
    fetchProjects()
  }

  const deleteProject = async (project: Project) => {
    try {
      await db.collection("projects").deleteOne({ _id: project._id })
    } catch (error) {
      console.log(error)
    }

    setEditModalOpen(false)
    fetchProjects()
  }

  const createProject = async (project: Project) => {
    if (userRole !== "admin") {
      delete project.tonnesHarvested
      delete project.tonnePrice
    }
    try {
      await db.collection("projects").insertOne(project)
    } catch (error) {
      console.log(error)
    }

    setEditModalOpen(false)
    fetchProjects()
  }

  return (
    <div>
      <Box
        sx={{
          background: "#ccc",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Header title="Projects"></Header>
        <Stack
          sx={{
            p: 2,
            flexWrap: "wrap",
            gap: 2,
          }}
          direction="row"
        >
          {projects.map((project, index) => (
            <ProjectCard
              key={index}
              project={project}
              onEdit={(project) => {
                setProjectToEdit(project)
                setEditModalOpen(true)
              }}
            />
          ))}
        </Stack>

        <Dialog
          open={editModalOpen}
          onClose={() => {
            setProjectToEdit(null)
            setEditModalOpen(false)
          }}
        >
          {projectToEdit ? (
            <EditProjectDialog
              project={projectToEdit || {}}
              onCancel={() => {
                setProjectToEdit(null)
                setEditModalOpen(false)
              }}
              onSave={(project) => {
                setProjectToEdit(null)
                updateProject(project)
              }}
              onDelete={(project) => {
                setProjectToEdit(null)
                deleteProject(project)
              }}
            />
          ) : (
            <NewProjectDialog
              onCancel={() => {
                setEditModalOpen(false)
              }}
              onSave={(project) => {
                createProject(project)
              }}
            />
          )}
        </Dialog>

        <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loadingScreenOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {showCreateEditProject && (
          <Fab
            color="primary"
            variant="extended"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            onClick={() => {
              setEditModalOpen(true)
            }}
          >
            <AddIcon />
            New project
          </Fab>
        )}
      </Box>
    </div>
  )
}
