import React from "react"
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  AlertProps,
} from "@mui/material"
import { useState } from "react"
import LockResetIcon from "@mui/icons-material/LockReset"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useRealmApp } from "../RealmApp/RealmApp"
import * as Realm from "realm-web"
import SnackbarWrapper from "../SnackbarWrapper/SnackbarWrapper"

export default function ResetPasswordPage() {
  const realm = useRealmApp()

  const navigate = useNavigate()

  const [newPassword, setNewPassword] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [searchParams] = useSearchParams()
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)

  const handleFormChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value)
  }

  const handleFormChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserEmail(event.target.value)
  }

  const resetPassword = async () => {
    console.log("reset password")
    const token = searchParams.get("token")
    const tokenId = searchParams.get("tokenId")
    if (token == null || tokenId == null) {
      console.error("Something went wrong when extracting token/tokenId")
      return
    }

    try {
      await realm.realmApp.emailPasswordAuth.resetPassword(
        token,
        tokenId,
        newPassword
      )

      // Log the user in
      await realm.logIn(Realm.Credentials.emailPassword(userEmail, newPassword))

      navigate("/")
    } catch (error) {
      if (error instanceof Error) {
        setSnackbar({ children: error.message, severity: "error" })
        console.error(error.message)
      }
    }
  }

  const [visible, setVisible] = useState(false)

  const toggleVisibility = () => {
    setVisible(!visible)
  }

  return (
    <div>
      <Paper
        sx={{
          mt: 10,
          mx: "auto",
          width: 600,
          p: 2,
        }}
      >
        <Box textAlign="center">
          <Typography variant="h6" component="h2">
            Set new password
          </Typography>
        </Box>

        <Box
          component="form"
          sx={{ width: 500, mx: "auto", rowGap: 1 }}
          display="grid"
        >
          <Box>
            <TextField
              label="E-mail"
              name="email"
              type="email"
              value={userEmail}
              sx={{ width: "100%" }}
              onChange={handleFormChangeEmail}
            ></TextField>
          </Box>
          <Box>
            <TextField
              label="New password"
              name="password"
              type={visible ? "text" : "password"}
              value={newPassword}
              sx={{ width: "100%" }}
              onChange={handleFormChangePassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => toggleVisibility()}
                    position="end"
                  >
                    {visible ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Box>

          <Box sx={{ mx: "auto" }} textAlign="center">
            <Button
              variant="contained"
              startIcon={<LockResetIcon />}
              onClick={resetPassword}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Paper>
      <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />
    </div>
  )
}
