import React, { ReactElement } from "react"
import * as Realm from "realm-web"
import realmConfig from "../../realm.json"

interface RealmAppContextProviderProps {
  realmApp: Realm.App
  currentUser: Realm.User | null
  logIn: (credentials: Realm.Credentials) => Promise<void>
  logOut: () => Promise<void>
  fetchUser: () => Promise<Realm.User | null>
}

function createRealmApp(id: string) {
  const baseUrl: string = realmConfig.baseUrl
  return new Realm.App({ id, baseUrl })
}

// Use context instead of passing down to children via props
const RealmAppContext =
  React.createContext<RealmAppContextProviderProps | null>(null)

export function RealmAppProvider({
  appId,
  children,
}: {
  appId: string
  children: ReactElement
}) {
  const [realmApp, setRealmApp] = React.useState(createRealmApp(appId))

  // Triggered whenever appId changes. If appId changes, all children will rerender and use the new realmApp.
  React.useEffect(() => {
    setRealmApp(createRealmApp(appId))
  }, [appId])

  const [currentUser, setCurrentUser] = React.useState(realmApp.currentUser)

  // Returns memoized callback
  const logIn = React.useCallback(
    async (credentials: Realm.Credentials) => {
      await realmApp.logIn(credentials)
      // Store the app's current user in state
      setCurrentUser(realmApp.currentUser)
    },
    [realmApp]
  )

  const logOut = React.useCallback(async () => {
    if (realmApp.currentUser !== null) {
      // Logs out and removes the user from the client
      await realmApp.removeUser(realmApp.currentUser)
    }
    setCurrentUser(null)
  }, [realmApp])

  const fetchUser = React.useCallback(async () => {
    if (!realmApp.currentUser) return null
    try {
      await realmApp.currentUser.refreshCustomData()
      setCurrentUser(realmApp.currentUser)
      return realmApp.currentUser
    } catch (error) {
      throw error
    }
  }, [realmApp])

  const realmAppContext: RealmAppContextProviderProps = React.useMemo(() => {
    return { realmApp, currentUser, logIn, logOut, fetchUser }
  }, [realmApp, currentUser, logIn, logOut, fetchUser])

  return (
    <RealmAppContext.Provider value={realmAppContext}>
      {children}
    </RealmAppContext.Provider>
  )
}

export function useRealmApp() {
  const realmApp = React.useContext(RealmAppContext)
  if (!realmApp) {
    throw new Error(
      `No Realm App found. Make sure to call useRealmApp() inside of a <RealmAppProvider />`
    )
  }
  return realmApp
}
