import { useEffect, useRef, useState } from "react"
import mapboxgl from "mapbox-gl"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { Box, useTheme, Button, Paper } from "@mui/material"
import { useMongoDB } from "../../MongoDB/MongoDB"
import { BSON } from "realm-web"
import BagDrop from "../../../types/BagDrop"
import Property from "../../../types/Property"
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode"
import {
  LocationSearching,
  MyLocation,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material"
import { Feature, FeatureCollection } from "geojson"
interface Props {
  projectId: BSON.ObjectId | null
  bagDrops: BagDrop[] | null
  onGeolocate: (pos: GeolocationPosition) => void
}

export default function HarvesterMap(props: Props) {
  const theme = useTheme()

  const { db } = useMongoDB()

  const [mapHasLoaded, setMapHasLoaded] = useState(false)

  const [properties, setProperties] = useState<Property[] | null>(null)

  const geoLocateControl = useRef<mapboxgl.GeolocateControl | null>(null)

  const drawColors = {
    selected: {
      harvestIsAllowed: {
        fill: theme.palette.success.dark,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.dark,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
    active: {
      harvestIsAllowed: {
        fill: theme.palette.success.light,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.light,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
    inactive: {
      harvestIsAllowed: {
        fill: theme.palette.success.light,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.light,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
  }

  const mapboxDrawStyles = [
    {
      id: "gl-draw-polygon-fill-active",
      type: "fill",
      filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
      paint: {
        "fill-color": [
          "case",
          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.active.harvestNotAllowed.fill,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.active.harvestIsAllowed.fill,

          // otherwise
          drawColors.active.unknownIfAllowed.fill,
        ],
        "fill-opacity": 0.5,
      },
    },
    {
      id: "gl-draw-polygon-fill-inactive",
      type: "fill",
      filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
      paint: {
        "fill-color": [
          "case",
          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.inactive.harvestNotAllowed.fill,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.inactive.harvestIsAllowed.fill,
          // otherwise
          drawColors.inactive.unknownIfAllowed.fill,
        ],
        "fill-opacity": 0.5,
      },
    },
    {
      id: "gl-draw-polygon-stroke-inactive",
      type: "line",
      paint: {
        "line-color": [
          "case",
          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.inactive.harvestNotAllowed.outline,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.inactive.harvestIsAllowed.outline,

          // otherwise
          drawColors.inactive.unknownIfAllowed.outline,
        ],
        "line-width": 2,
      },
    },

    {
      id: "labels",
      type: "symbol",
      layout: {
        "text-field": ["get", "user_propertyOfFeature"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-anchor": "top",
      },
    },

  ]

  const draw = useRef(
    new MapboxDraw({
      userProperties: true,
      styles: mapboxDrawStyles,
      modes: {
        ...MapboxDraw.modes,
        static: StaticMode, // Disable the user from moving polygons
      },
      controls: {
        point: false,
        line_string: false,
        polygon: false,
        trash: false,
        combine_features: false,
        uncombine_features: false,
      },
    })
  )

  const [propertiesAreRendered, setPropertiesAreRendered] = useState(false)

  const renderProperties = async (properties: Property[]) => {
    const propertiesGeojson: Feature[] = []

    for (let i = 0; i < properties.length; i++) {
      const property = properties[i]
      const index = i

      if (!property.geojson || !Array.isArray(property.geojson)) continue

      for (let j = 0; j < property.geojson.length; j++) {
        const feature = property.geojson[j]

        // Make sure Draw can read the harvestIsAllowed property so that it applies the correct styling
        if (feature?.properties?.harvestIsAllowed === "no") {
          feature.properties["user_harvestIsAllowed"] = false
        } else if (feature?.properties?.harvestIsAllowed === "yes") {
          feature.properties["user_harvestIsAllowed"] = true
        }

        if (!feature.properties) feature.properties = {}
        console.log(feature.properties)
        feature.properties["user_propertyOfFeature"] = property.name || "Property " + (index + 1)
        console.log(feature.properties)
        propertiesGeojson.push(feature)
      }
    }

    const propertiesFeatureCollection: FeatureCollection = {
      type: "FeatureCollection",
      features: propertiesGeojson,
    }

    if (map.current?.getSource("properties")) {
      setPropertiesAreRendered(true)
      return
    }

    // Add source and layer with bagdrop markers
    map.current?.addSource("properties", {
      type: "geojson",
      // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
      // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      data: propertiesFeatureCollection,
    })

    map.current?.addLayer({
      source: "properties",
      id: "gl-draw-polygon-fill-inactive",
      type: "fill",
      paint: {
        "fill-color": [
          "case",
          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.inactive.harvestNotAllowed.fill,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.inactive.harvestIsAllowed.fill,
          // otherwise
          drawColors.inactive.unknownIfAllowed.fill,
        ],
        "fill-opacity": 0.5,
      },
    })

    map.current?.addLayer({
      source: "properties",
      id: "labels",
      type: "symbol",
      layout: {
        "text-field": ["get", "user_propertyOfFeature"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-anchor": "top",
      },
    })

    setPropertiesAreRendered(true)
  }

  mapboxgl.accessToken =
    "pk.eyJ1IjoibGFyc2h2aWRzdGVuIiwiYSI6ImNranlkb21vczA5OGUycW1xamZ6bXJnbTIifQ.I5-Nc4Mzi9sUDusCg91ibA"
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const markers = useRef<mapboxgl.Marker[]>([])
  const [lng, setLng] = useState(10.500250055043084)
  const [lat, setLat] = useState(59.91639856489616)

  const [geoLocateOn, setGeoLocateOn] = useState<boolean>(false)

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current || "",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: 5,
    })
    map.current.on("load", async () => {
      map.current?.addSource("norgeskart-topo4", {
        type: "raster",
        tiles: [
          "https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=toporaster4&zoom={z}&x={x}&y={y}",
        ],
        tileSize: 128,
      })

      map.current?.addLayer({
        id: "norgeskart-topo4",
        type: "raster",
        source: "norgeskart-topo4",
        paint: {},
      })
      map.current?.addControl(draw.current, "top-left")
      geoLocateControl.current = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserLocation: true,
        showAccuracyCircle: true,
      })
        .on("geolocate", (e) => {
          const pos = e as GeolocationPosition
          props.onGeolocate(pos)
        })
        .on("trackuserlocationstart", () => {
          setGeoLocateOn(true)
        })
        .on("trackuserlocationend", () => {
          setGeoLocateOn(false)
        })
      map.current?.addControl(geoLocateControl.current)

      draw.current.changeMode("static") // Disable the user from moving polygons

      setMapHasLoaded(true)
    })
  })

  useEffect(() => {
    if (!map.current) return // wait for map to initialize
    map.current.on("move", () => {
      if (!map.current) return // wait for map to initialize
      setLng(map.current.getCenter().lng)
      setLat(map.current.getCenter().lat)
    })
  })

  useEffect(() => {
    if (!mapHasLoaded) return
    // Hide default geo locate button css class mapboxgl-ctrl-geolocate
    const geoLocateButton = document.getElementsByClassName(
      "mapboxgl-ctrl-geolocate"
    )[0]
    if (geoLocateButton) {
      geoLocateButton.setAttribute("style", "display: none")
    }
  }, [mapHasLoaded])

  function handleZoomInClick() {
    if (!map.current) return // wait for map to initialize
    map.current.zoomIn()
  }

  function handleZoomOutClick() {
    if (!map.current) return // wait for map to initialize
    map.current.zoomOut()
  }

  function handleGeolocateClick() {
    if (!map.current) return // wait for map to initialize
    geoLocateControl.current?.trigger()
  }

  useEffect(() => {
    const fetchProperties = async () => {
      if (!db) return
      if (!mapHasLoaded) return
      if (!props.projectId) return
      const fetchedProperties = await db
        .collection("properties")
        .find({ projectId: props.projectId })
      setProperties(fetchedProperties)
    }

    fetchProperties()
  }, [db, props.projectId, mapHasLoaded])

  useEffect(() => {
    if (!map.current) return // wait for map to initialize
    if (!properties) return
    if (!mapHasLoaded) return
    renderProperties(properties)
    // Turn on geolocation immediately (first time will request for permission)
    //geoLocateControl.current?.trigger()
  }, [properties, mapHasLoaded])

  useEffect(() => {
    if (!props.bagDrops) return
    if (!mapHasLoaded) return
    if (!propertiesAreRendered) return

    const bagdropsGeojson = props.bagDrops.map((bagdrop) => ({
      ...bagdrop.geojson,
      properties: {},
    }))
    const bagdropsFeatureCollection: FeatureCollection = {
      type: "FeatureCollection",
      features: bagdropsGeojson,
    }

    if (map.current?.getSource("bagdrops")) {
      const source = map.current?.getSource(
        "bagdrops"
      ) as mapboxgl.GeoJSONSource
      source.setData(bagdropsFeatureCollection)
      return
    }

    // Add source and layer with bagdrop markers
    map.current?.addSource("bagdrops", {
      type: "geojson",
      // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
      // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      data: bagdropsFeatureCollection,
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    })

    map.current?.addLayer({
      id: "clusters",
      type: "circle",
      source: "bagdrops",
      filter: ["has", "point_count"],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#d6bb51",
          100,
          "#75f0f1",
          750,
          "#b18cf2",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      },
    })

    map.current?.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "bagdrops",
      filter: ["has", "point_count"],
      layout: {
        "text-field": ["get", "point_count_abbreviated"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    })

    map.current?.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "bagdrops",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": "#daB411",
        "circle-radius": 14,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
    })
  }, [props.bagDrops, mapHasLoaded, propertiesAreRendered])

  return (
    <>
      <Box
        sx={{
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div ref={mapContainer} className="map-container" />
      </Box>
      <Paper
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          p: 2,
          gap: 1,
          display: "flex",
          flexDirection: "column",
          margin: 1,
          borderRadius: 4,
        }}
      >
        <Button
          onClick={handleGeolocateClick} //() => setZoom((prevZoom) => (prevZoom += 1))}
          variant="contained"
          sx={{ width: 100, height: 100, borderRadius: 4 }}
        >
          {geoLocateOn ? (
            <MyLocation fontSize="large" sx={{ width: 50, height: 50 }} />
          ) : (
            <LocationSearching
              fontSize="large"
              sx={{ width: 50, height: 50 }}
            />
          )}
        </Button>
        <Button
          onClick={handleZoomInClick} //() => setZoom((prevZoom) => (prevZoom += 1))}
          variant="contained"
          sx={{ width: 100, height: 100, borderRadius: 4 }}
        >
          <ZoomIn fontSize="large" sx={{ width: 50, height: 50 }} />
        </Button>
        <Button
          onClick={handleZoomOutClick} //() => setZoom((prevZoom) => (prevZoom -= 1))}
          variant="contained"
          sx={{ width: 100, height: 100, borderRadius: 4 }}
        >
          <ZoomOut fontSize="large" sx={{ width: 50, height: 50 }} />
        </Button>
      </Paper>
    </>
  )
}
