import { Box, Button, Typography } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import PropertyOwner from "../../../types/PropertyOwner"

interface Props {
  propertyOwner: PropertyOwner
  updatePropertyOwner: (propertyOwner: PropertyOwner) => Promise<void>
  setChildButtonPress: Dispatch<SetStateAction<string>>
}

export default function GDPRConsentCompleted(props: Props) {
  const conditionalGdprAnswer = () => {
    if (props.propertyOwner?.gdprConsent) {
      return (
        <Box>
          <Typography variant="h6" align="center">
            Tusen takk for registrering
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            Vi vil informere deg via SMS når vi begynner å høste
          </Typography>
        </Box>
      )
    } else if (!props.propertyOwner?.gdprConsent) {
      return (
        <Box>
          <Typography
            variant="body1"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            Svaret ditt er registrert:
          </Typography>
          <Typography variant="body1" align="center">
            Du ønsker ikke at Algea Harvest høster tang på eiendommen din
          </Typography>
        </Box>
      )
    }
  }

  const onClickChange = async () => {
    await props.updatePropertyOwner({
      ...props.propertyOwner,
      gdprConsent: undefined,
    })
    props.setChildButtonPress("Resubmit")
  }

  return (
    <Box
      sx={{
        background: "#fff",
        py: 1,
        m: 1,
        p: 2,
        borderRadius: 3,
        boxShadow: 1,
        width: "80%",
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{}}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box
          component="img"
          sx={{
            height: 130,
            width: 100,
          }}
          src="/logo.svg"
        />
        {conditionalGdprAnswer()}
        <Button
          size="large"
          // variant='outlined'
          onClick={onClickChange}
          sx={{ borderRadius: 3, mt: 2 }}
        >
          Endre?
        </Button>
      </Box>
    </Box>
  )
}
