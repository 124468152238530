import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import React from "react"
import InvoiceType from "../../../types/Invoice"

interface Props {
  invoice: InvoiceType
}

const sxTable1Row = {
  border: "1px solid",
}

const sxTable1Cell = {
  borderBottom: "1px solid",
}

const sxTable2Row = sxTable1Row
const sxTable2Cell = sxTable1Cell

export default function Invoice(props: Props) {
  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <Box
        sx={{
          maxWidth: 800,
          mx: "auto",
          p: 8,
          border: "1px solid",
        }}
      >
        <Typography variant="h5">Faktura</Typography>

        <Stack direction="row" justifyContent="space-between" sx={{ pt: 1 }}>
          <Stack>
            <Box fontWeight="bold">Kjøper</Box>
            <Box>Algea AS</Box>
            <Box>Postboks 2143</Box>
            <Box>Kristiansund</Box>
          </Stack>

          <Stack>
            <Box fontWeight="bold">Grunneier</Box>
            <Box>{props.invoice.owner.name}</Box>
            <Box>
              {props.invoice.owner.address}, {props.invoice.owner.postalCode}{" "}
              {props.invoice.owner.postalCity}
            </Box>
          </Stack>
        </Stack>

        <TableContainer sx={{ pt: 4 }}>
          <Table
            sx={{
              width: 300,
            }}
            size="small"
          >
            <TableBody>
              <TableRow sx={sxTable1Row}>
                <TableCell sx={sxTable1Cell} variant="head">
                  Fakturadato
                </TableCell>
                <TableCell sx={sxTable1Cell}>
                  {props.invoice.invoiceDate?.toLocaleDateString("nb-NO") || ""}
                </TableCell>
              </TableRow>
              <TableRow sx={sxTable1Row}>
                <TableCell sx={sxTable1Cell} variant="head">
                  Forfallsdato
                </TableCell>
                <TableCell sx={sxTable1Cell}>
                  {props.invoice.dueDate?.toLocaleDateString("nb-NO") || ""}
                </TableCell>
              </TableRow>
              <TableRow sx={sxTable1Row}>
                <TableCell sx={sxTable1Cell} variant="head">
                  Fakturanummer
                </TableCell>
                <TableCell sx={sxTable1Cell}></TableCell>
              </TableRow>
              <TableRow sx={sxTable1Row}>
                <TableCell sx={sxTable1Cell} variant="head">
                  Kontonummer
                </TableCell>
                <TableCell sx={sxTable1Cell}>
                  {props.invoice.owner.bankAccount}
                </TableCell>
              </TableRow>
              <TableRow sx={sxTable1Row}>
                <TableCell sx={sxTable1Cell} variant="head">
                  Telefon
                </TableCell>
                <TableCell sx={sxTable1Cell}>
                  {props.invoice.owner.phoneNumber}
                </TableCell>
              </TableRow>
              <TableRow sx={sxTable1Row}>
                <TableCell sx={sxTable1Cell} variant="head">
                  E-post
                </TableCell>
                <TableCell sx={sxTable1Cell}>
                  {props.invoice.owner.email}
                </TableCell>
              </TableRow>
              <TableRow sx={sxTable1Row}>
                <TableCell sx={sxTable1Cell} variant="head">
                  Kommentar
                </TableCell>
                <TableCell sx={sxTable1Cell}>
                  {props.invoice.owner.comment}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ pt: 2 }}>
          <TableContainer sx={{ pt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow sx={sxTable2Row}>
                  <TableCell sx={{ ...sxTable2Cell, fontWeight: "bold" }}>
                    Eiendom
                  </TableCell>
                  <TableCell sx={{ ...sxTable2Cell, fontWeight: "bold" }}>
                    Andel
                  </TableCell>
                  <TableCell sx={{ ...sxTable2Cell, fontWeight: "bold" }}>
                    Varenr
                  </TableCell>
                  <TableCell sx={{ ...sxTable2Cell, fontWeight: "bold" }}>
                    Vare/tjeneste
                  </TableCell>
                  <TableCell sx={{ ...sxTable2Cell, fontWeight: "bold" }}>
                    Antall tonn
                  </TableCell>
                  <TableCell sx={{ ...sxTable2Cell, fontWeight: "bold" }}>
                    Pris kr/tonn
                  </TableCell>
                  <TableCell sx={{ ...sxTable2Cell, fontWeight: "bold" }}>
                    Sum
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.invoice.properties.map((property, index) => (
                  <TableRow key={index} sx={sxTable2Row}>
                    <TableCell sx={{ ...sxTable2Cell, width: 50 }}>
                      {property.propertyNumbers.map((pn, index) => (
                        <span key={index}>{pn}, </span>
                      ))}
                    </TableCell>
                    <TableCell sx={sxTable2Cell}>
                      {property.propertyShare.toFixed(2)}
                    </TableCell>
                    <TableCell sx={sxTable2Cell}></TableCell>
                    <TableCell sx={sxTable2Cell}>Råtang</TableCell>
                    <TableCell sx={sxTable2Cell}>
                      {property.tonnesHarvested.toFixed(2)}
                    </TableCell>
                    <TableCell sx={sxTable2Cell}>
                      {props.invoice.tonnePrice.toFixed(2)}
                    </TableCell>
                    <TableCell sx={sxTable2Cell}>
                      {property.totalPrice.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer sx={{ pt: 4 }}>
            <Table
              sx={{
                width: 300,
              }}
              size="small"
            >
              <TableBody>
                <TableRow sx={sxTable1Row}>
                  <TableCell sx={sxTable1Cell} variant="head">
                    Sum eks. MVA
                  </TableCell>
                  <TableCell sx={sxTable1Cell}>
                    {props.invoice.totalPriceWithoutVat.toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow sx={sxTable1Row}>
                  <TableCell sx={sxTable1Cell} variant="head">
                    MVA 25%
                  </TableCell>
                  <TableCell sx={sxTable1Cell}>
                    {props.invoice.vat.toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow sx={sxTable1Row}>
                  <TableCell sx={sxTable1Cell} variant="head">
                    Sum ink. MVA
                  </TableCell>
                  <TableCell sx={sxTable1Cell}>
                    {props.invoice.totalPriceWithVat.toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box sx={{ pt: 4 }}>
          <Typography variant="body2" fontStyle="italic">
            Til orientering:
          </Typography>
          <Typography variant="body2" fontStyle="italic">
            Dette er oppgjør for tang høstet på Deres eiendom (landslott). Det
            gjøres oppmerksom på at faktura er utarbeidet av Algea AS på vegne
            av grunneier.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
