import { InfoOutlined, Person } from "@mui/icons-material"
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material"
import { grey } from "@mui/material/colors"
import React, { useEffect, useState } from "react"
import { BSON } from "realm-web"
import Property from "../../../types/Property"
import PropertyOwner from "../../../types/PropertyOwner"
import PropertyNumber from "../../../types/PropertyNumber"

interface SelectPropertyDialogProps {
  properties: Property[]
  propertyNumbers: PropertyNumber[]
  owners: PropertyOwner[]
  onSelectProperty: (property: Property) => void
}

export default function SelectPropertyDialog(props: SelectPropertyDialogProps) {
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(
    null
  )

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const propertyId = new BSON.ObjectID(event.target.value)
    const property = props.properties.filter((p) => {
      return p._id?.equals(propertyId)
    })[0]

    setSelectedProperty(property)
    props.onSelectProperty(property)
  }

  useEffect(() => {
    if (selectedProperty) return

    const firstProperty = props.properties.at(0)
    if (!firstProperty) return

    setSelectedProperty(firstProperty)
    props.onSelectProperty(firstProperty)
  }, [props.properties])

  return (
    <div>
      <Stack
        spacing={2}
        sx={{
          maxWidth: 275,
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <Box>
          <Typography variant="h6" fontWeight="700">
            Select Property
          </Typography>
          <Box
            sx={{
              display: "flex",
              mb: 1,
            }}
          >
            <Typography variant="caption" fontWeight="400" color={grey[600]}>
              Select a property from the dropdown below in order to draw map
              areas for this property
            </Typography>
          </Box>
        </Box>

        {props.properties.length ? (
          <Stack spacing={2}>
            <Box>
              <FormControl fullWidth={true}>
                <InputLabel id="form-propertyId">Select Property</InputLabel>
                <Select
                  labelId="form-propertyId"
                  label="Select Property"
                  name="propertyId"
                  value={selectedProperty?._id?.toString() || ""}
                  onChange={(e) => {
                    handleSelectChange(e as SelectChangeEvent<string>)
                  }}
                >
                  {props.properties.map((property, index) => (
                    <MenuItem
                      key={index}
                      value={property._id?.toString() || ""}
                    >
                      {property.name || `Property ${index + 1}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              {selectedProperty?.propertyNumberIds?.length ? (
                <Box>
                  <Typography variant="body1" fontWeight={800}>
                    Property Numbers:
                  </Typography>
                  <Typography
                    variant="caption"
                    fontWeight="400"
                    color={grey[600]}
                  >
                    The selected property have these property numbers
                  </Typography>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                      maxHeight: 350,
                      overflow: "auto",
                    }}
                  >
                    {selectedProperty.propertyNumberIds.map(
                      (propertyNumberId, index) => (
                        <ListItem key={index}>
                          {
                            <ListItemText
                              primary={
                                props.propertyNumbers.find((propertyNumber) => {
                                  return propertyNumber._id.equals(
                                    propertyNumberId
                                  )
                                })?.propertyNumber
                              }
                            />
                          }
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <InfoOutlined
                    fontSize="medium"
                    color="action"
                    sx={{ mr: 0.5 }}
                  />
                  <Typography variant="body2" color={grey[700]}>
                    There are no registered property numbers on this property.
                    You can add property numbers and owners from the "Owners"
                    tab
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
        ) : (
          <div>
            <Box>
              There are no properties on this project. Go to the Properties and
              Owners tab to create properties.
            </Box>
          </div>
        )}
      </Stack>
    </div>
  )
}
