import { Box } from "@mui/material"
import React, { useEffect, useState } from "react"
import * as Realm from "realm-web"
import { BSON } from "realm-web"
import { useMongoDB } from "../MongoDB/MongoDB"
import { useParams } from "react-router-dom"
import { useRealmApp } from "../RealmApp/RealmApp"
import PropertyOwner from "../../types/PropertyOwner"
import GDPRConsentForm from "./GDPRConsentForm/GDPRConsentForm"
import GDPRConsentConfirmation from "./GDPRConsentConfirmation/GDPRConsentConfirmation"
import GDPRConsentCompleted from "./GDPRConsentCompleted/GDPRConsentCompleted"
import GDPRConsentInfo from "./GDPRConsentInfo/GDPRConsentInfo"

export default function GDPRConsentFormPage() {
  const realmApp = useRealmApp()

  let { propertyOwnerId } = useParams()

  const { db } = useMongoDB()

  const [propertyOwner, setPropertyOwner] = useState<PropertyOwner | null>(null)

  const [childButtonPress, setChildButtonPress] = useState<string>("nothing")

  const [pageStep, setPageStep] = useState<number>(1)
  const [tempGdprConsent, setTempGdprConsent] = useState<boolean>(false)

  const updatePropertyOwner = async (propertyOwner: PropertyOwner) => {
    try {
      await db
        .collection("propertyOwners")
        .updateOne({ _id: propertyOwner._id }, { $set: propertyOwner })
    } catch (error) {
      console.log(error)
      return
    }
  }

  function conditionalConsentComponent() {
    if (propertyOwner != null) {
      if (
        propertyOwner.gdprConsent === undefined ||
        propertyOwner.gdprConsent === null
      ) {
        switch (pageStep) {
          case 1:
            return (
              <GDPRConsentForm
                propertyOwner={propertyOwner}
                setChildButtonPress={setChildButtonPress}
                setPropertyOwner={setPropertyOwner}
                setPageStep={setPageStep}
                setTempGdprConsent={setTempGdprConsent}
              />
            )
          case 2:
            return <GDPRConsentInfo setPageStep={setPageStep} />
          case 3:
            return (
              <GDPRConsentConfirmation
                propertyOwner={propertyOwner}
                updatePropertyOwner={updatePropertyOwner}
                setChildButtonPress={setChildButtonPress}
                setPropertyOwner={setPropertyOwner}
                setPageStep={setPageStep}
                tempGdprConsent={tempGdprConsent}
              />
            )
        }
      } else {
        return (
          <GDPRConsentCompleted
            propertyOwner={propertyOwner}
            updatePropertyOwner={updatePropertyOwner}
            setChildButtonPress={setChildButtonPress}
          />
        )
      }
    }
  }

  useEffect(() => {
    const credentials = Realm.Credentials.anonymous()
    try {
      // Authenticate the user
      realmApp.logIn(credentials)
      // `App.currentUser` updates to match the logged in user
    } catch (err) {
      console.error("Failed to log in", err)
    }
  }, [])

  React.useEffect(() => {
    if (!db) return
    if (!realmApp.currentUser) return
    const fetchPropertyOwner = async () => {
      try {
        const fetchedPropertyOwner = await db
          .collection("propertyOwners")
          .findOne({ _id: new BSON.ObjectID(propertyOwnerId) })
        console.log(propertyOwnerId)
        console.log(fetchedPropertyOwner)
        setPropertyOwner(fetchedPropertyOwner)
      } catch (error) {
        if (error instanceof Error) {
          console.log(error)
        }
      }
    }
    fetchPropertyOwner()
  }, [db, propertyOwnerId, realmApp.currentUser, childButtonPress])

  return (
    <div>
      <Box
        sx={{
          bgcolor: "#ccc",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {conditionalConsentComponent()}
        </Box>
      </Box>
    </div>
  )
}
