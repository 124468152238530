import {
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect, useState } from "react"
import Property from "../../../types/Property"
import PropertyOwner from "../../../types/PropertyOwner"
import PropertyNumber from "../../../types/PropertyNumber"

interface OwnerSelected extends PropertyOwner {
  isSelected?: boolean
}

interface SMSDialogProps {
  properties: Property[]
  propertyNumbers: PropertyNumber[]
  owners: PropertyOwner[]
  open: boolean
  onSendSMS: (owners: PropertyOwner[], msg: string) => void
  onCancel: () => void
}

interface SelectOwnersProps {
  owners: OwnerSelected[]
  setOwners: React.Dispatch<React.SetStateAction<OwnerSelected[]>>
  enableSelect: boolean
}

interface WriteSMSMessageProps {
  messageText: string
  setMessageText: React.Dispatch<React.SetStateAction<string>>
  isDisabled: boolean
}

function SelectOwners(props: SelectOwnersProps) {
  return (
    <Box sx={{ p: 2 }}>
      {props.enableSelect && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const editedOwners = props.owners.map((owner: OwnerSelected) => {
                return { ...owner, isSelected: event.target.checked }
              })
              props.setOwners(editedOwners)
            }}
          />
          <Typography variant="body1">Select All</Typography>
        </Box>
      )}

      <Box maxHeight="350px" overflow="auto">
        <TableContainer>
          <Table>
            <TableBody>
              {props.owners?.map((owner) => (
                <TableRow key={owner._id.toString()}>
                  {props.enableSelect && (
                    <TableCell>
                      <Checkbox
                        color="primary"
                        sx={{ p: 0 }}
                        checked={owner.isSelected}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const editedOwners = props.owners.map(
                            (ownerMap: OwnerSelected) => {
                              if (ownerMap._id.equals(owner._id)) {
                                return {
                                  ...ownerMap,
                                  isSelected: event.target.checked,
                                }
                              } else {
                                return { ...ownerMap }
                              }
                            }
                          )
                          props.setOwners(editedOwners)
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell align="center" sx={{ p: 1 }}>
                    {owner.name}
                  </TableCell>
                  <TableCell align="center" sx={{ p: 1 }}>
                    {owner.phoneNumber}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {props.enableSelect && (
        <Typography variant="body2" color="grey.500" sx={{ mt: 2 }}>
          Only property numbers where the owner name and phone number are
          defined, and the owner has consented to harvesting, are shown
        </Typography>
      )}
    </Box>
  )
}

function WriteSMSMessage(props: WriteSMSMessageProps) {
  return (
    <Box display="flex" width="100%" justifyContent="center">
      <Box width={() => (props.isDisabled ? "90%" : "60%")}>
        <TextField
          id="outlined-multiline-flexible"
          label="SMS Message"
          multiline
          minRows={4}
          maxRows={12}
          value={props.messageText}
          onChange={(e) => props.setMessageText(e.target.value)}
          fullWidth
          disabled={props.isDisabled}
        />
      </Box>
    </Box>
  )
}

export default function SMSDialog(props: SMSDialogProps) {
  const [selectableOwners, setSelectableOwners] = useState<OwnerSelected[]>(
    [] as OwnerSelected[]
  )

  useEffect(() => {
    let newSelectableOwners: OwnerSelected[] = []

    newSelectableOwners = props.owners.filter((owner) => {
      return (
        owner.phoneNumber &&
        owner.phoneNumber > 0 &&
        owner.gdprConsent === true &&
        owner.name &&
        props.propertyNumbers.find((propertyNumber) => {
          // return true if owner is on property number
          return (
            propertyNumber.owners?.find((oopn) => {
              return oopn.ownerId.equals(owner._id)
            }) !== undefined
          )
        })
      )
    })

    newSelectableOwners.forEach((owner) => {
      owner.isSelected = false
    })

    setSelectableOwners(newSelectableOwners)
  }, [props.properties])

  const [messageText, setMessageText] = useState<string>("")

  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleClose = () => {
    props.onCancel()
    setActiveStep(0)
  }
  const handleSendSMS = () => {
    props.onSendSMS(
      selectableOwners.filter((owner) => {
        return owner.isSelected === true
      }),
      messageText
    )
    props.onCancel()
    setActiveStep(0)
  }

  return (
    <Dialog
      open={props.open}
      // onClose={handleClose}
      maxWidth="xl"
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{ p: 2 }}
        height="600px"
        width="900px"
        justifyContent="space-between"
      >
        <Box>
          <Box display="flex" justifyContent="right">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step key={"select"}>
              <StepLabel>Select Property Owners</StepLabel>
            </Step>
            <Step key={"message"}>
              <StepLabel>Write SMS Message</StepLabel>
            </Step>
            <Step key={"confirmation"}>
              <StepLabel>Confirm and Send</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <Box>
          {activeStep === 0 && (
            <SelectOwners
              owners={selectableOwners}
              setOwners={setSelectableOwners}
              enableSelect={true}
            />
          )}
          {activeStep === 1 && (
            <WriteSMSMessage
              messageText={messageText}
              setMessageText={setMessageText}
              isDisabled={false}
            />
          )}
          {activeStep === 2 && (
            <Box display="flex">
              <Box width="60%">
                <SelectOwners
                  owners={selectableOwners.filter((owner) => {
                    return owner.isSelected === true
                  })}
                  setOwners={setSelectableOwners}
                  enableSelect={false}
                />
              </Box>
              <Box width="40%">
                <WriteSMSMessage
                  messageText={messageText}
                  setMessageText={setMessageText}
                  isDisabled={true}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="right">
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          {activeStep !== 2 && <Button onClick={handleNext}>Next</Button>}
          {activeStep === 2 && (
            <Button
              disabled={
                selectableOwners.filter((el) => {
                  return el.isSelected === true
                }).length === 0
              }
              onClick={handleSendSMS}
            >
              Send SMS
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
