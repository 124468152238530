import { Box, Button, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import Project from "../../../types/Project"
import EditProjectForm from "../EditProjectForm/EditProjectForm"

interface Props {
  project: Project
  onSave: (project: Project) => void
  onCancel: () => void
  onDelete: (project: Project) => void
}

export default function EditProjectDialog(props: Props) {
  const [editedProject, setEditedProject] = useState<Project>(props.project)

  return (
    <div>
      <Box sx={{ py: 2, px: 4 }}>
        <Typography variant="h6" component="h2">
          Edit project
        </Typography>

        <Box>
          <EditProjectForm
            project={props.project}
            onChange={(project) => {
              setEditedProject(project)
            }}
          />
        </Box>

        <Stack direction="row" spacing={1} pt={1} justifyContent="center">
          <Button
            variant="outlined"
            onClick={() => {
              props.onDelete(editedProject)
            }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              props.onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.onSave(editedProject)
            }}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </div>
  )
}
