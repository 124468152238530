import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

interface Props {
  title: string
  path: string
  icon: JSX.Element
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export default function SideNavigationListItem(props: Props) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        selected={window.location.pathname.startsWith(props.path)}
        onClick={props.onClick}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.title} />
      </ListItemButton>
    </ListItem>
  )
}
