import { createTheme } from "@mui/material/styles"
import "typeface-mulish"

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0073AB",
      light: "#D0D9EE",
    },
    secondary: {
      main: "#f2d74b",
    },
  },
  typography: {
    fontFamily: "Mulish",
    h1: {
      fontWeight: 500,
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    body1: {
      fontWeight: 400,
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
})
