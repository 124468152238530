import MenuIcon from "@mui/icons-material/Menu"
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import SideNavigation from "../SideNavigation/SideNavigation"

interface Props {
  title: string
  children?: React.ReactNode
}

export default function Header(props: Props) {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  return (
    <div id="headerContainer">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              size="large"
              aria-label="menu"
              onClick={() => {
                setOpenDrawer(true)
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              textAlign="left"
              pl={2}
              sx={{
                flexGrow: 1,
              }}
            >
              {props.title}
            </Typography>

            {props.children}
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* hack so that appbar doesnt eat up page content */}
      </Box>

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        PaperProps={{
          sx: {
            width: 250,
          },
        }}
      >
        <SideNavigation
          onClick={() => {
            setOpenDrawer(false)
          }}
        />
      </Drawer>
    </div>
  )
}
