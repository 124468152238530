import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useRealmApp } from "../RealmApp/RealmApp"

const MongoDBContext = React.createContext<any>({ value: null })

const MongoDB = ({ children }: { children: ReactElement }) => {
  const { currentUser } = useRealmApp()
  const [db, setDb] = useState<Realm.Services.MongoDBDatabase | null>(null) // any -> MongoDBDatabase

  useEffect(() => {
    if (currentUser !== null) {
      const realmService = currentUser.mongoClient("mongodb-atlas")
      currentUser.refreshCustomData().then(() => {
        // Refreshing custom user data to make sure custom user data is synchronized on refresh of page
        // (user does not have to log out to refresh anymore)
        setDb(realmService.db("algea"))
      })
    }
  }, [currentUser])

  return (
    <MongoDBContext.Provider
      value={{
        db,
      }}
    >
      {children}
    </MongoDBContext.Provider>
  )
}

export const useMongoDB = () => {
  const mdbContext = useContext(MongoDBContext)
  if (mdbContext == null) {
    throw new Error("useMongoDB() called outside of a MongoDB?")
  }
  return mdbContext
}

export default MongoDB
