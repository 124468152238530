import Header from "../Header/Header"
import { Button, Modal } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import NewUser from "./NewUser/NewUser"
import { useState } from "react"
import UsersDataGrid from "./UsersDataGrid/UsersDataGrid"

export default function UsersPage() {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <div>
      <Header title="Users">
        <Button color="inherit" startIcon={<AddIcon />} onClick={openModal}>
          New user
        </Button>
      </Header>
      <UsersDataGrid />

      <Modal open={modalOpen} onClose={closeModal}>
        <div>
          <NewUser />
        </div>
      </Modal>
    </div>
  )
}
