import { Button, Typography } from "@mui/material"
import AddLocationIcon from "@mui/icons-material/AddLocation"

interface Props {
  onClick: () => void
  disabled: boolean
}

export default function BagDropButton(props: Props) {
  return (
    <Button
      variant="contained"
      size="large"
      sx={{
        width: 200,
        height: 75,
        borderRadius: 4,
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <AddLocationIcon
        sx={{
          fontSize: 40,
        }}
      />
      <Typography variant="h6">Drop bag</Typography>
    </Button>
  )
}
