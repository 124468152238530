import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import PropertyNumber from "../../../types/PropertyNumber"
import PropertyOwner from "../../../types/PropertyOwner"
import { Close } from "@mui/icons-material"
import EditPropertyNumberForm from "./PropertyNumberForm"
import { useState } from "react"
import { BSON } from "realm-web"

interface EditPropertyNumberDialog {
  propertyNumber?: PropertyNumber
  open: boolean
  onClose: () => void
  onSave: (propertyNumber: PropertyNumber) => void
  onDelete: (propertyNumber: PropertyNumber) => void
}

export default function EditPropertyNumberDialog(
  props: EditPropertyNumberDialog
) {
  const [propertyNumber, setPropertyNumber] = useState<PropertyNumber>(
    props.propertyNumber || { _id: new BSON.ObjectID(), propertyNumber: "" }
  )

  const onChange = (propertyNumber: PropertyNumber) => {
    setPropertyNumber(propertyNumber)
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose()
        }}
      >
        <Stack direction="column" spacing={1} sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h2">
              Edit property number
            </Typography>
            <IconButton
              onClick={() => {
                props.onClose()
              }}
            >
              <Close />
            </IconButton>
          </Box>

          <Box>
            <EditPropertyNumberForm
              propertyNumber={props.propertyNumber}
              onChange={onChange}
            />
          </Box>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                props.onDelete(propertyNumber)
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                props.onSave(propertyNumber)
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </div>
  )
}
