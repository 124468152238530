import { Box, Stack, TextField } from "@mui/material"
import PropertyNumber from "../../../types/PropertyNumber"
import PropertyOwner from "../../../types/PropertyOwner"
import { useEffect, useState } from "react"
import { BSON } from "realm-web"

interface Props {
  propertyNumber?: PropertyNumber
  onChange: (propertyNumber: PropertyNumber) => void
}

const newPropertyNumber = {
  _id: new BSON.ObjectID(),
  propertyNumber: "",
}

export default function EditPropertyNumberForm(props: Props) {
  const [formPropertyNumber, setFormPropertyNumber] = useState<PropertyNumber>(
    props.propertyNumber || newPropertyNumber
  )

  const handleFormPropertyNumberChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormPropertyNumber({
      ...formPropertyNumber,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    props.onChange(formPropertyNumber)
  }, [formPropertyNumber])

  return (
    <div>
      <Stack spacing={2} sx={{ pt: 2 }}>
        <Box
          component="form"
          sx={{ width: 250, mx: "auto", rowGap: 1 }}
          display="grid"
        >
          <Box>
            <TextField
              label="Property number"
              name="propertyNumber"
              sx={{ width: "100%" }}
              value={formPropertyNumber?.propertyNumber || ""}
              onChange={handleFormPropertyNumberChange}
            ></TextField>
          </Box>
        </Box>
      </Stack>
    </div>
  )
}
