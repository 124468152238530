import { Box, Button, Stack, Typography } from "@mui/material"
import { Dispatch, SetStateAction } from "react"

interface Props {
  setPageStep: Dispatch<SetStateAction<number>>
}

export default function GDPRConsentInfo(props: Props) {
  const tableHead = ["Trinn", "Beskrivelse"]

  const documentBody = [
    [
      "Innhenting av opplysninger",
      `Landeiere kontaktes for informasjon før hver høsting og oppdateres får
       hver utfakturering av vederlag. Denne informasjonen inneholder: 
       Navn, 
       Adresse, 
       Telefonnummer, 
       e-postadresse, 
       Bankkontonummer, 
       Eierforhold med kart over G.nr/ B.nr de er eiere av,
       Høstet mengde på personens eiendom`,
    ],
    [
      "Registrering av opplysninger",
      `Opplysninger registreres i et register over landeiere vi handler med, i 
      kontrakt og på faktura utstedt på vegne av landeierene`,
    ],
    [
      "Innsyn",
      `Landeiere han når som helst be om å få innsyn i hva som er registrert på 
      dem. Henvendelse om innsyn i hvilke personopplysninger som er registrert, 
      samt begjæring om innsyn kan rettes til: info@algea.com`,
    ],
    [
      "Sikring av personopplysninger",
      `Personopplysninger er registrert i system der kun nødvendige ansatte har 
      tilgang. Utlevering av opplysninger til eksterne parter skjer kun etter 
      krav fra myndigheter.`,
    ],
    [
      "Sletting av personopplysninger",
      `Personopplysninger oppbevares så lenge personene har en kontrakt med bedriften eller det er
      planer om ny høsting på deres grunn og så lenge det er nødvendig etterpå pa grunn av pliktig
      oppbevaring av regnskapsmateriell.
      
      Arkivene gjennomgås halvårlig med hensyn å slette ikke nødvendig materiale. Slikt utgått
      materiel, i papirutgave, makuleres.
      
      For bedriftens behov for & bevare historien så oppbevares kun G.nr / B.nr og høstet mengde.`,
    ],
  ]

  const onClickYes = async () => {
    props.setPageStep(3)
  }

  const onClickNo = async () => {
    props.setPageStep(1)
  }

  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Box
          component="img"
          sx={{
            height: 130,
            width: 100,
          }}
          src="/logo.svg"
        />
        <Typography variant="h6" align="center">
          Algea GDPR Dokument
        </Typography>

        <Box sx={{ overflow: "scroll", p: 1 }}>
          {documentBody.map((step, index) => {
            return (
              <div key={index}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {step[0]}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {step[1]}
                </Typography>
              </div>
            )
          })}
        </Box>

        <Stack
          direction="row"
          gap={4}
          sx={{
            pt: 2,
            pb: 1,
          }}
        >
          <Button
            size="large"
            variant="outlined"
            onClick={onClickNo}
            sx={{ borderRadius: 3 }}
          >
            Gå Tilbake
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={onClickYes}
            sx={{
              borderRadius: 3,
            }}
          >
            Godkjenn
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
