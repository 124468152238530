import {
  AlertProps,
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import {
  Person,
  Place,
  Description,
  DirectionsBoat,
  Download,
} from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import Project, { ProjectStatus } from "../../../types/Project"
import { useRealmApp } from "../../RealmApp/RealmApp"
import { useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import SnackbarWrapper from "../../SnackbarWrapper/SnackbarWrapper"
import BSON from "bson"
import { useMongoDB } from "../../MongoDB/MongoDB"
import PropertyOwner from "../../../types/PropertyOwner"
import Invoice from "../../../types/Invoice"

import * as turf from "@turf/turf"

interface Props {
  project: Project
  onEdit: (project: Project) => void
}

export default function ProjectCard(props: Props) {
  const { currentUser } = useRealmApp()
  const { db } = useMongoDB()
  const theme = useTheme()

  const [downloadUrl, setDownloadUrl] = useState("")
  const [downloadLoading, setDownloadLoading] = useState(false)

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)

  async function handleDownload() {
    let projectIdString = props.project._id.toString()
    let fileUrl = ""
    if (projectIdString && currentUser !== null && downloadUrl === "") {
      // Fetch signed url
      setDownloadLoading(true)
      try {
        // Get information to put into report
        const bagDrops = await db
          .collection("bagDrops")
          .find({ projectId: new BSON.ObjectId(projectIdString) })

        const users = await db.collection("users").find()

        const properties = await db
          .collection("properties")
          .find({ projectId: new BSON.ObjectID(projectIdString) })

        const greenColor = theme.palette.success.main
        const yellowColor = theme.palette.secondary.main
        const redColor = theme.palette.error.main
        const propertiesGeojson = properties
          .map((property: any) => {
            const features = property.geojson

            const name = property.name || ""
            let color = yellowColor

            features?.map((feature: any) => {
              if (
                feature.properties.harvestIsAllowed === "yes" ||
                feature.properties.harvestIsAllowed === "true"
              ) {
                color = greenColor
              } else if (
                feature.properties.harvestIsAllowed === "no" ||
                feature.properties.harvestIsAllowed === "false"
              ) {
                color = redColor
              }

              delete feature.properties
              feature.properties = {}

              feature.properties.color = color
              feature.properties.name = name
            })

            return features
          })
          .filter((n: any) => n)

        const propertiesGeojsonFlattened = propertiesGeojson.flat()

        const bagDropsFormatted = bagDrops.map((bagDrop: any, index: any) => {
          let withinArea = "No"

          // Check if bag drop is within area
          // In that case, set withinArea to "Yes"

          propertiesGeojsonFlattened.map((feature: any) => {
            if (feature.geometry.type === "Polygon") {
              if (
                feature.geometry.coordinates[0].length > 0 &&
                feature.geometry.coordinates[0][0].length > 0
              ) {
                const polygon = turf.polygon(feature.geometry.coordinates)
                const point = turf.point(bagDrop.geojson.geometry.coordinates)
                if (turf.booleanPointInPolygon(point, polygon)) {
                  withinArea = "Yes"
                }
              }
            } else if (feature.geometry.type === "MultiPolygon") {
              if (
                feature.geometry.coordinates[0].length > 0 &&
                feature.geometry.coordinates[0][0].length > 0
              ) {
                const multiPolygon = turf.multiPolygon(
                  feature.geometry.coordinates
                )
                const point = turf.point(bagDrop.geojson.geometry.coordinates)
                if (turf.booleanPointInPolygon(point, multiPolygon)) {
                  withinArea = "Yes"
                }
              }
            }
          })

          return {
            harvesterName:
              users.find((user: any) => user.userId === bagDrop.droppedByUserId)
                ?.name || "Unknown",
            bagNumber: index + 1,
            date: bagDrop.dropTime.toLocaleDateString("no-NO"),
            time: bagDrop.dropTime.toLocaleTimeString("no-NO"),
            coordinates: `${bagDrop.geojson.geometry.coordinates[0]}, ${bagDrop.geojson.geometry.coordinates[1]}`,
            withinArea: withinArea,
          }
        })

        const bagDropsGeojson = bagDrops.map((bagDrop: any) => {
          return bagDrop.geojson
        })

        const bagDropsGeojsonFormatted = bagDropsGeojson.map((geojson: any) => {
          return { properties: {}, ...geojson }
        })

        const owners = await db
          .collection("propertyOwners")
          .find({ projectId: new BSON.ObjectID(projectIdString) })

        const ownerIds = owners.map(
          (owner: any) => new BSON.ObjectId(owner._id)
        )

        const invoices = await db
          .collection("invoices")
          .find({ ownerId: { $in: ownerIds } })

        const invoicesFormatted = invoices.map((invoice: any) => {
          let invoiceFormatted = {
            things: invoice.items,
            items: null,
            ...invoice,
          }
          delete invoiceFormatted.items
          return invoiceFormatted
        })

        const propertyNumbersAll = await db
          .collection("propertyNumbers")
          .find({})

        const propertiesFormatted = properties.map((property: any) => {
          const propertyOwners = []

          for (let i = 0; i < property.propertyNumberIds?.length; i++) {
            const propertyNumberObject = propertyNumbersAll.find(
              (propertyNumber: any) =>
                propertyNumber._id.toString() ===
                property.propertyNumberIds[i].toString()
            )

            for (let j = 0; j < propertyNumberObject.owners?.length; j++) {
              const ownerObject = owners.find(
                (owner: any) =>
                  owner._id.toString() ===
                  propertyNumberObject.owners[j].ownerId.toString()
              )
              const owner = {
                propertyNumber: propertyNumberObject.propertyNumber,
                ownerName: ownerObject?.name || "",
                share: propertyNumberObject?.owners[j].share || "",
                accountNumber: ownerObject?.accountNumber || "",
                address: ownerObject?.address || "",
                postalCode: ownerObject?.postalCode || "",
                postalCity: ownerObject?.postalCity || "",
                email: ownerObject?.email || "",
                phoneNumber: ownerObject?.phoneNumber || "",
                GDPRStatus: ownerObject?.GDPRStatus || "",
              }
              propertyOwners.push(owner)
            }
          }

          return {
            propertyName: property.name || "",
            bagsHarvested: property.bagsHarvested || "0",
            propertyNumbers: propertyOwners,
          }
        })

        // fetch and download
        const projectReportFields = {
          projectName: props.project.name,
          startDate:
            props.project.createdDate?.toLocaleDateString("no-NO") || "",
          tonnesHarvested: props.project.tonnesHarvested || "",
          bagdropsGeojson: bagDropsGeojsonFormatted,
          areasGeojson: propertiesGeojsonFlattened, // POLYGONS med properties "name" og "color"
          properties: propertiesFormatted,
          bagdrops: bagDropsFormatted,
          invoices: invoicesFormatted,
        }

        console.log(projectReportFields)
        // throw new Error("Not implemented")

        const myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("X-API-KEY", "d27aNTA4NzoyMDk4OjVTdG5kYm1ESThOeFJhb0c")
        //myHeaders.append("Access-Control-Allow-Origin", "*")

        const apiString = JSON.stringify(projectReportFields)

        const requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          body: apiString,
          redirect: "follow",
        }

        fetch(
          "https://rest.apitemplate.io/v2/create-pdf?template_id=42d77b230bc2c852",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            console.log(result)
            const filename = JSON.parse(result).download_url
            console.log(filename)
            setDownloadUrl(filename)
            window.open(filename, "_blank")
            setDownloadLoading(false)
          })
          .catch((error) => {
            setSnackbar({ children: error.message, severity: "error" })
            setDownloadLoading(false)
          })
      } catch (error) {
        if (error instanceof Error) {
          setSnackbar({ children: error.message, severity: "error" })
        }
        console.error("Failed fetching project report")
        setDownloadLoading(false)
        return
      }
    } else if (downloadUrl !== "") {
      window.open(downloadUrl, "_blank")
    }
  }

  const navigate = useNavigate()

  const statusToString = (status: string) => {
    switch (status) {
      case ProjectStatus.UNKNOWN:
        return "Unknown status"
      case ProjectStatus.EDITING:
        return "Editing"
      case ProjectStatus.READY:
        return "Ready for harvesting"
      case ProjectStatus.ONGOING:
        return "Harvesting ongoing"
      case ProjectStatus.COMPLETED:
        return "Harvesting completed"
      case ProjectStatus.LOCKED:
        return "Locked and invoiced"
      default:
        return "Unknown status"
    }
  }

  const userRole = currentUser?.customData.role

  const showPropertiesAndOwners =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"
  const showDrawPropertyMap =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"
  const showInvoices =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"
  const showHarvesterMap =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn" ||
    userRole === "hoster"
  const showDownloadProjectReport =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"
  const showCreateEditProject =
    userRole === "admin" || userRole === "tanglandAdmin"
  const showTonnePrice =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"
  const showTonnesHarvested =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          width: 400,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Box textAlign="left">
            <Tooltip title={props.project.name || ""}>
              <Typography variant="h5" noWrap={true} maxWidth={320}>
                {props.project.name}
              </Typography>
            </Tooltip>

            <Typography
              sx={{
                whiteSpace: "pre-wrap",
              }}
            >
              {statusToString(props.project.status) || " "}
            </Typography>
          </Box>

          {showCreateEditProject && (
            <Box>
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  props.onEdit(props.project)
                }}
              >
                <EditIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            textAlign: "center",
            pt: 2,
          }}
        >
          {showTonnePrice && (
            <Box>
              <Typography>Price per tonne</Typography>
              <Typography
                fontSize={30}
                sx={{
                  minHeight: 45,
                }}
              >
                {props.project.tonnePrice}
              </Typography>
            </Box>
          )}

          {showTonnesHarvested && (
            <Box>
              <Typography>Tonnes Harvested</Typography>
              <Typography fontSize={30}>
                {props.project.tonnesHarvested}
              </Typography>
            </Box>
          )}
        </Box>

        <Stack
          direction="column"
          spacing={1}
          sx={{
            px: 2,
            pb: 2,
            pt: 1,
            alignItems: "start",
          }}
        >
          {showPropertiesAndOwners && (
            <Button
              startIcon={<Person />}
              variant="outlined"
              onClick={() => {
                navigate(`/project/${props.project._id?.toString()}/owners`)
              }}
            >
              Properties and Owners
            </Button>
          )}

          {showDrawPropertyMap && (
            <Button
              startIcon={<Place />}
              variant="outlined"
              onClick={() => {
                navigate(`/project/${props.project._id?.toString()}/map`)
              }}
            >
              Draw Property Map
            </Button>
          )}

          {showInvoices && (
            <Button
              startIcon={<Description />}
              variant="outlined"
              onClick={() => {
                navigate(`/project/${props.project._id?.toString()}/invoices`)
              }}
            >
              Invoices
            </Button>
          )}

          {showHarvesterMap && (
            <Button
              startIcon={<DirectionsBoat />}
              variant="outlined"
              onClick={() => {
                navigate(
                  `/project/${props.project._id?.toString()}/harvester-map`
                )
              }}
            >
              Harvester Map
            </Button>
          )}

          {showDownloadProjectReport && (
            <LoadingButton
              loading={downloadLoading}
              loadingPosition="start"
              variant="outlined"
              startIcon={<Download />}
              onClick={handleDownload}
            >
              {downloadUrl === ""
                ? "Download Project Report"
                : "Open Project Report"}
            </LoadingButton>
          )}
        </Stack>
      </Card>
      <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />
    </div>
  )
}
