import React, { useCallback, useEffect, useState } from "react"
import { useMongoDB } from "../MongoDB/MongoDB"
import {
  AlertProps,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Drawer,
  Stack,
} from "@mui/material"
import { Add, Send } from "@mui/icons-material"
import PropertyRow from "./PropertyRow/PropertyRow"
import Property from "../../types/Property"
import PropertyNumber from "../../types/PropertyNumber"
import EditPropertyDialog from "./EditPropertyDialog/EditPropertyDialog"
import CreatePropertyDialog from "./CreatePropertyDialog/CreatePropertyDialog"
import { BSON } from "realm-web"
import SnackbarWrapper from "../SnackbarWrapper/SnackbarWrapper"
import PropertyOwner from "../../types/PropertyOwner"
import SMSDialog from "./SMSDialog/SMSDialog"
import { useRealmApp } from "../RealmApp/RealmApp"

interface Props {
  projectIdString?: string
}

export default function PropertyOwnersView(props: Props) {
  const projectId = new BSON.ObjectId(props.projectIdString)

  const { db } = useMongoDB()

  const [properties, setProperties] = useState<Property[]>([])
  const [propertyNumbers, setPropertyNumbers] = useState<PropertyNumber[]>([])
  const [propertyOwners, setPropertyOwners] = useState<PropertyOwner[]>([])

  const [openSMSDialog, setOpenSMSDialog] = React.useState<boolean>(false)

  const fetchProperties = useCallback(async () => {
    setLoadingScreenOpen(true)
    // Fetch basic property info
    let properties: Property[]
    try {
      properties = await db
        .collection("properties")
        .find({ projectId: projectId })
    } catch (error) {
      console.log(error)
      return
    }

    // Fetch property numbers for each property
    let propertyNumbers: PropertyNumber[] = []
    for (let i = 0; i < properties.length; i++) {
      const propertyNumberIdsSubset = properties[i].propertyNumberIds || []
      let propertyNumbersSubset: PropertyNumber[] = []
      for (let j = 0; j < propertyNumberIdsSubset.length; j++) {
        try {
          const onePropertyNumber = await db
            .collection("propertyNumbers")
            .findOne({ _id: propertyNumberIdsSubset[j] })
          propertyNumbersSubset.push(onePropertyNumber)
        } catch (error) {
          console.log(error)
          return
        }
      }
      propertyNumbers.push(...propertyNumbersSubset)
    }

    // Fetch owners for each property number
    let propertyOwners: PropertyOwner[] = []
    try {
      propertyOwners = await db
        .collection("propertyOwners")
        .find({ projectId: projectId })
    } catch (error) {
      console.log(error)
      return
    }

    setProperties(properties)
    setPropertyNumbers(propertyNumbers)
    setPropertyOwners(propertyOwners)

    setLoadingScreenOpen(false)
  }, [db])

  const [loadingScreenOpen, setLoadingScreenOpen] = useState<boolean>(true)

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)

  const [openCreatePropertyDialog, setOpenCreatePropertyDialog] =
    React.useState<boolean>(false)
  const [openEditPropertyDialog, setOpenEditPropertyDialog] =
    React.useState<boolean>(false)
  const [propertyToEdit, setPropertyToEdit] = useState<Property | null>(null)
  const onOpenCreatePropertyClick = () => {
    setPropertyToEdit(null)
    setOpenCreatePropertyDialog(true)
  }
  const onOpenEditPropertyClick = (property: Property) => {
    setPropertyToEdit(property)
    setOpenEditPropertyDialog(true)
  }

  useEffect(() => {
    if (!db) return

    fetchProperties()
  }, [db, fetchProperties])

  const updateProperty = async (property: Property) => {
    try {
      await db
        .collection("properties")
        .findOneAndUpdate(
          { _id: property._id },
          { $set: property },
          { returnNewDocument: true }
        )
    } catch (error) {
      console.log(error)
      return
    }

    setOpenEditPropertyDialog(false)
    fetchProperties()
  }

  const deleteProperty = async (property: Property) => {
    try {
      await db.collection("properties").deleteOne({ _id: property._id })
    } catch (error) {
      console.log(error)
    }

    setOpenEditPropertyDialog(false)
    fetchProperties()
  }

  const createProperty = async (property: Property) => {
    const propertyIntoDatabase = {
      ...property,
      projectId: projectId,
      _id: new BSON.ObjectID(), //jalla
    }

    try {
      await db.collection("properties").insertOne(propertyIntoDatabase)
    } catch (error) {
      console.log(error)
    }

    setOpenEditPropertyDialog(false)
    fetchProperties()
  }

  const { realmApp } = useRealmApp()

  const updateOwner = async (owner: PropertyOwner) => {
    try {
      await db
        .collection("propertyOwners")
        .findOneAndUpdate(
          { _id: owner._id },
          { $set: owner },
          { returnNewDocument: true }
        )
    } catch (error) {
      console.log(error)
      return
    }
  }

  const sendSMSMultiple = async (owners: PropertyOwner[], msg: string) => {
    const msgEncoded = encodeURIComponent(msg)
    const from = encodeURIComponent(`Algea`)
    try {
      owners.forEach((owner: PropertyOwner) => {
        const to = owner.phoneNumber
        realmApp.currentUser?.functions
          .sendSveveSMS(from, to, msgEncoded)
          .then((result) => {
            if (result.status === "success") {
              const newSmsCount = (owner.smsCount ?? 0) + 1
              const newOwner = {
                ...owner,
                smsCount: newSmsCount,
              }
              updateOwner(newOwner)
            } else if (result.status === "error") {
              setSnackbar({ children: "Could not send SMS", severity: "error" })
            }
          })
          .catch((exception) => {
            console.log(
              `Error calling sendSveveSMS realm function: ${exception}`
            )
          })
      })
    } catch (exception) {
      console.log(`Error sending sms ${exception}`)
    }
  }

  const { currentUser } = useRealmApp()
  const userRole = currentUser?.customData.role
  const showAddProperty = userRole === "admin" || userRole === "tanglandAdmin"
  const showSendSmsToMultipleOwners =
    userRole === "admin" || userRole === "tanglandAdmin"

  return (
    <div>
      <Stack
        sx={{
          pt: 2,
          bgcolor: "#ccc",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          {properties.map((property, key) => (
            <Box
              key={key}
              sx={{
                background: "#fff",
                py: 1,
                m: 1,
                p: 1,
                borderRadius: 1,
                boxShadow: 1,
                width: "95%",
                maxWidth: "1300px",
              }}
            >
              <PropertyRow
                index={key}
                property={property}
                propertyNumbers={propertyNumbers.filter((propertyNumber) => {
                  if (!property.propertyNumberIds?.length) return false
                  for (let i = 0; i < property.propertyNumberIds?.length; i++) {
                    if (
                      propertyNumber._id.equals(property.propertyNumberIds[i])
                    )
                      return true
                  }
                  return false
                })}
                propertyOwners={propertyOwners}
                onEditProperty={(property) => {
                  onOpenEditPropertyClick(property)
                }}
                onChange={() => {
                  fetchProperties()
                }}
              />
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
            mt: 1,
          }}
        >
          {showAddProperty && (
            <Button
              size="large"
              startIcon={<Add />}
              variant="contained"
              onClick={() => {
                onOpenCreatePropertyClick()
              }}
            >
              Add property
            </Button>
          )}
        </Box>
      </Stack>

      {showSendSmsToMultipleOwners && (
        <Button
          size="large"
          variant="contained"
          endIcon={<Send />}
          onClick={() => {
            setOpenSMSDialog(true)
          }}
          sx={{
            position: "sticky",
            bottom: 16,
            left: 16,
          }}
        >
          Send SMS to Multiple Owners
        </Button>
      )}

      {propertyToEdit && (
        <EditPropertyDialog
          open={openEditPropertyDialog}
          property={propertyToEdit}
          onSave={(property) => {
            updateProperty(property)
          }}
          onDelete={(property) => {
            deleteProperty(property)
          }}
          onClose={() => {
            setOpenEditPropertyDialog(false)
          }}
        />
      )}
      <CreatePropertyDialog
        open={openCreatePropertyDialog}
        onSave={(property) => {
          createProperty(property)
          setOpenCreatePropertyDialog(false)
        }}
        onClose={() => {
          setOpenCreatePropertyDialog(false)
        }}
      />

      <SMSDialog
        properties={properties}
        propertyNumbers={propertyNumbers}
        owners={propertyOwners}
        open={openSMSDialog}
        onCancel={() => {
          setOpenSMSDialog(false)
        }}
        onSendSMS={sendSMSMultiple}
      />

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingScreenOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />
    </div>
  )
}
