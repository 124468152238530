import { BSON } from "realm-web"

export enum ProjectStatus {
  UNKNOWN = "unknown",
  EDITING = "editing",
  READY = "ready",
  ONGOING = "ongoing",
  COMPLETED = "completed",
  LOCKED = "locked",
}

export default interface Project {
  _id: BSON.ObjectID
  name: string
  status: ProjectStatus
  tonnePrice?: number
  tonnesHarvested?: number
  createdDate?: Date
}
