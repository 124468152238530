import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import PropertyNumber from "../../../types/PropertyNumber"
import Property from "../../../types/Property"
import ArrayTextFields from "../../ArrayTextFields/ArrayTextFields"
import { BSON } from "realm-web"

interface EditPropertyFormProps {
  property: Property
  onChange: (property: Property) => void
}

export default function EditPropertyForm(props: EditPropertyFormProps) {
  const [formProperty, setFormProperty] = useState<Property>({
    _id: new BSON.ObjectID(),
    projectId: new BSON.ObjectId(),
    propertyNumberIds: [],
    name: "",
    comment: "",
    bagsHarvested: 0,
  })

  useEffect(() => {
    if (props.property === formProperty) return
    setFormProperty(props.property)
  }, [props.property])

  const handleFormChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormProperty({
      ...formProperty,
      [event.target.name]: event.target.value,
    })
  }
  const handleFormChangeNumber = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "") {
      setFormProperty({ ...formProperty, [event.target.name]: "" })
    } else {
      setFormProperty({
        ...formProperty,
        [event.target.name]: parseFloat(event.target.value) ?? undefined,
      })
    }
  }

  useEffect(() => {
    if (!formProperty) return
    props.onChange(formProperty)
  }, [formProperty])

  return (
    <div>
      <Stack spacing={2} sx={{ pt: 2 }}>
        <Box
          component="form"
          sx={{ width: 250, mx: "auto", rowGap: 1 }}
          display="grid"
        >
          <Box>
            <TextField
              label="Property name"
              name="name"
              sx={{ width: "100%" }}
              value={formProperty?.name || ""}
              onChange={handleFormChange}
            ></TextField>
          </Box>

          <Box>
            <TextField
              label="Comment"
              name="comment"
              multiline
              sx={{ width: "100%" }}
              value={formProperty?.comment || ""}
              onChange={handleFormChange}
            ></TextField>
          </Box>
        </Box>
      </Stack>
    </div>
  )
}
