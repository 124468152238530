import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import mapboxgl, { LngLatBounds } from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import "./MapView.css"
import { Box } from "@mui/system"

import MapboxDraw from "@mapbox/mapbox-gl-draw"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import { useMongoDB } from "../MongoDB/MongoDB"
import { AlertProps, Backdrop, Button, Link, Paper, Stack } from "@mui/material"
import SnackbarWrapper from "../SnackbarWrapper/SnackbarWrapper"
import { useRealmApp } from "../RealmApp/RealmApp"

import { useTheme } from "@mui/material/styles"
import Property from "../../types/Property"
import EditAreaDialog from "./EditAreaDialog/EditAreaDialog"
import SelectPropertyDialog from "./SelectPropertyDialog/SelectPropertyDialog"
import PropertyNumber from "../../types/PropertyNumber"
import FeatureWithId from "../../types/FeatureWithId"
import { ArrowBack, ArrowForward, Print } from "@mui/icons-material"
import MapConfig from "../../types/MapConfig"
import { BSON } from "realm-web"
import PrintableMap from "./PrintableMap/PrintableMap"
import PropertyOwner from "../../types/PropertyOwner"

interface Props {
  projectIdString?: string
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    let resizeTimer: NodeJS.Timeout;

    function updateSize() {
      console.log(window.innerWidth, window.innerHeight)
      setSize([window.innerWidth, window.innerHeight]);
    }

    function debouncedUpdateSize() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(updateSize, 200); // Adjust the delay as needed (e.g., 200 milliseconds).
    }

    window.addEventListener('resize', debouncedUpdateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', debouncedUpdateSize);
    };
  }, []);

  return size;
}


export default function MapView(props: Props) {
  const projectId = new BSON.ObjectID(props.projectIdString)

  const theme = useTheme()
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)
  const [disabledMessageOpen, setDisabledMessageOpen] = useState<boolean>(false)

  const { currentUser } = useRealmApp()
  const userRole = currentUser?.customData.role
  const showEditArea = userRole === "admin" || userRole === "tanglandAdmin"
  const showDrawButtons = userRole === "admin" || userRole === "tanglandAdmin"

  mapboxgl.accessToken =
    "pk.eyJ1IjoibGFyc2h2aWRzdGVuIiwiYSI6ImNranlkb21vczA5OGUycW1xamZ6bXJnbTIifQ.I5-Nc4Mzi9sUDusCg91ibA"
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const [lng, setLng] = useState(10.500250055043084)
  const [lat, setLat] = useState(59.91639856489616)
  const [zoom, setZoom] = useState(5)

  const [printLng, setPrintLng] = useState(10.500250055043084)
  const [printLat, setPrintLat] = useState(59.91639856489616)
  const [printZoom, setPrintZoom] = useState(5)
  const [printBbox, setPrintBbox] = useState<LngLatBounds>()
  const [printMapHeight, setPrintMapHeight] = useState(window.innerHeight)
  const [printMapWidth, setPrintMapWidth] = useState(window.innerWidth)

  const [windowWidth, windowHeight] = useWindowSize()

  useEffect(() => {
    setPrintLng(lng)
  }, [lng])
  useEffect(() => {
    setPrintLat(lat)
  }, [lat])
  useEffect(() => {
    setPrintZoom(zoom)
  }, [zoom])

  useEffect(() => {
    console.log('effect')
    console.log(map.current?.getBounds().toArray())
    console.log('....')
    setPrintBbox(map.current?.getBounds())
    setPrintMapHeight(windowHeight)
    setPrintMapWidth(windowWidth)
  }, [windowWidth, windowHeight, zoom, lat, lng])

  const drawColors = {
    selected: {
      harvestIsAllowed: {
        fill: theme.palette.success.dark,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.dark,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
    active: {
      harvestIsAllowed: {
        fill: theme.palette.success.light,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.light,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
    inactive: {
      harvestIsAllowed: {
        fill: theme.palette.success.light,
        outline: theme.palette.success.main,
        vertex: theme.palette.info.main,
      },
      harvestNotAllowed: {
        fill: theme.palette.error.light,
        outline: theme.palette.error.main,
        vertex: theme.palette.info.main,
      },
      unknownIfAllowed: {
        fill: theme.palette.secondary.light,
        outline: theme.palette.secondary.main,
        vertex: theme.palette.info.main,
      },
    },
  }

  const mapboxDrawStyles = [
    {
      id: "gl-draw-polygon-fill-active",
      type: "fill",
      filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
      paint: {
        "fill-color": [
          "case",
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], false],
          ],
          drawColors.selected.harvestNotAllowed.fill,
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], true],
          ],
          drawColors.selected.harvestIsAllowed.fill,
          ["==", ["get", "user_isSelected"], true],
          drawColors.selected.unknownIfAllowed.fill,

          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.active.harvestNotAllowed.fill,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.active.harvestIsAllowed.fill,

          // otherwise
          drawColors.active.unknownIfAllowed.fill,
        ],
        "fill-opacity": [
          "case",
          ["==", ["get", "user_isSelected"], true],
          0.1,
          // not selected
          0.0,
        ],
      },
    },
    {
      id: "gl-draw-polygon-fill-inactive",
      type: "fill",
      filter: ["all", ["==", "active", "false"], ["==", "$type", "Polygon"]],
      paint: {
        "fill-color": [
          "case",
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], false],
          ],
          drawColors.selected.harvestNotAllowed.fill,
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], true],
          ],
          drawColors.selected.harvestIsAllowed.fill,
          ["==", ["get", "user_isSelected"], true],
          drawColors.selected.unknownIfAllowed.fill,

          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.inactive.harvestNotAllowed.fill,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.inactive.harvestIsAllowed.fill,

          // otherwise
          drawColors.inactive.unknownIfAllowed.fill,
        ],
        "fill-opacity": [
          "case",
          ["==", ["get", "user_isSelected"], true],
          0.1,
          // not selected
          0.0,
        ],
      },
    },
    {
      id: "gl-draw-polygon-stroke-inactive",
      type: "line",
      filter: [
        "all",
        ["==", "active", "false"],
        ["==", "$type", "Polygon"],
        ["!=", "mode", "static"],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], false],
          ],
          drawColors.selected.harvestNotAllowed.outline,
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], true],
          ],
          drawColors.selected.harvestIsAllowed.outline,
          ["==", ["get", "user_isSelected"], true],
          drawColors.selected.unknownIfAllowed.outline,

          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.inactive.harvestNotAllowed.outline,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.inactive.harvestIsAllowed.outline,

          // otherwise
          drawColors.inactive.unknownIfAllowed.outline,
        ],
        "line-width": [
          "case",
          ["==", ["get", "user_isSelected"], true],
          5,
          // not selected
          2,
        ],
      },
    },
    {
      id: "gl-draw-polygon-stroke-active",
      type: "line",
      filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], false],
          ],
          drawColors.selected.harvestNotAllowed.outline,
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], true],
          ],
          drawColors.selected.harvestIsAllowed.outline,
          ["==", ["get", "user_isSelected"], true],
          drawColors.selected.unknownIfAllowed.outline,

          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.active.harvestNotAllowed.outline,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.active.harvestIsAllowed.outline,

          // otherwise
          drawColors.active.unknownIfAllowed.outline,
        ],
        "line-dasharray": [0.2, 2],
        "line-width": [
          "case",
          ["==", ["get", "user_isSelected"], true],
          5,
          // not selected
          2,
        ],
      },
    },
    {
      id: "gl-draw-line-active",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], false],
          ],
          drawColors.selected.harvestNotAllowed.outline,
          [
            "all",
            ["==", ["get", "user_isSelected"], true],
            ["==", ["get", "user_harvestIsAllowed"], true],
          ],
          drawColors.selected.harvestIsAllowed.outline,
          ["==", ["get", "user_isSelected"], true],
          drawColors.selected.unknownIfAllowed.outline,

          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.active.harvestNotAllowed.outline,
          ["==", ["get", "user_harvestIsAllowed"], true],
          drawColors.active.harvestIsAllowed.outline,

          // otherwise
          drawColors.active.unknownIfAllowed.outline,
        ],
        "line-dasharray": [0.2, 2],
        "line-width": [
          "case",
          ["==", ["get", "user_isSelected"], true],
          5,
          // not selected
          2,
        ],
      },
    },

    {
      id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
      type: "circle",
      filter: [
        "all",
        ["==", "meta", "vertex"],
        ["==", "$type", "Point"],
        ["!=", "mode", "static"],
      ],
      paint: {
        "circle-radius": 5,
        "circle-color": "#fff",
      },
    },
    {
      id: "gl-draw-polygon-and-line-vertex-inactive",
      type: "circle",
      filter: [
        "all",
        ["==", "meta", "vertex"],
        ["==", "$type", "Point"],
        ["!=", "mode", "static"],
      ],
      paint: {
        "circle-radius": 3,
        "circle-color": [
          "case",
          ["==", ["get", "user_harvestIsAllowed"], false],
          drawColors.inactive.harvestNotAllowed.vertex,
          // harvestIsAllowed == true:
          drawColors.inactive.harvestIsAllowed.vertex,
        ],
      },
    },
  ]

  let customSimpleSelectMode = MapboxDraw.modes.simple_select
  const { onClick: _onClick } = MapboxDraw.modes.simple_select

  customSimpleSelectMode.onClick = function (...args) {
    if (
      args[1].featureTarget?.properties?.user_isSelected === false &&
      !this.getSelected()
    ) {
      showFeaturePopup(args[1])
      return
    }
    if (
      args[1].featureTarget?.properties?.user_isSelected === false &&
      this.getSelected()
    ) {
      showFeaturePopup(args[1])
      this.clearSelectedFeatures()
      return
    }
    _onClick?.apply(this, args)
  }

  const showFeaturePopup = (event: MapboxDraw.MapMouseEvent) => {
    const lngLat = event.lngLat
    const propertyOfFeature =
      event.featureTarget.properties?.user_propertyOfFeature

    const propertyNameOfFeature =
      event.featureTarget.properties?.user_propertyNameOfFeature
    const html =
      propertyNameOfFeature !== "" && propertyNameOfFeature !== undefined
        ? propertyNameOfFeature
        : `Property ${propertyOfFeature + 1}`

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      closeOnMove: false,
    })
    if (!map.current) return
    popup.setLngLat(lngLat).setHTML(html).addTo(map.current)

    setTimeout(popup.remove, 1000)
  }

  const controls = {
    point: false,
    line_string: false,
    polygon: true,
    trash: true,
    combine_features: false,
    uncombine_features: false,
  }

  if (!showDrawButtons) {
    controls.polygon = false
    controls.trash = false
  }

  const draw = useRef(
    new MapboxDraw({
      userProperties: true,
      styles: mapboxDrawStyles,
      modes: {
        ...MapboxDraw.modes,
        simple_select: customSimpleSelectMode,
      },
      controls: controls,
    })
  )

  const { db } = useMongoDB()
  const { realmApp } = useRealmApp()

  const [properties, setProperties] = useState<Property[]>([])
  const [propertyNumbers, setPropertyNumbers] = useState<PropertyNumber[]>([])
  const [propertyOwners, setPropertyOwners] = useState<PropertyOwner[]>([])

  const fetchProperties = useCallback(async () => {
    // Fetch basic property info
    let properties: Property[]
    try {
      properties = await db
        .collection("properties")
        .find({ projectId: projectId })
    } catch (error) {
      console.log(error)
      return
    }

    // Fetch property numbers for each property
    let propertyNumbers: PropertyNumber[] = []
    for (let i = 0; i < properties.length; i++) {
      const propertyNumberIdsSubset = properties[i].propertyNumberIds || []
      let propertyNumbersSubset: PropertyNumber[] = []
      for (let j = 0; j < propertyNumberIdsSubset.length; j++) {
        try {
          const onePropertyNumber = await db
            .collection("propertyNumbers")
            .findOne({ _id: propertyNumberIdsSubset[j] })
          propertyNumbersSubset.push(onePropertyNumber)
        } catch (error) {
          console.log(error)
          return
        }
      }
      propertyNumbers.push(...propertyNumbersSubset)
    }

    // Fetch owners for each property number
    let propertyOwners: PropertyOwner[] = []
    try {
      propertyOwners = await db
        .collection("propertyOwners")
        .find({ projectId: projectId })
    } catch (error) {
      console.log(error)
      return
    }

    setProperties(properties)
    setPropertyNumbers(propertyNumbers)
    setPropertyOwners(propertyOwners)

    return properties
  }, [db])

  useEffect(() => {
    if (!selectedProperty.current) return
    const sp = selectedProperty.current
    selectedProperty.current = properties.filter((property) => {
      return property._id?.equals(sp._id || "")
    })[0]
  }, [properties])

  // Set up the map once
  useEffect(() => {
    if (map.current) return
    if (db === null) return
    if (!projectId) return

    // Get center position and zoom level from db
    const fetchMapConfig = async () => {
      let ret = null
      try {
        ret = await db.collection("users").findOne(
          {
            userId: realmApp.currentUser?.id,
            "mapConfigs.projectId": new BSON.ObjectID(projectId),
          },
          {
            projection: {
              mapConfigs: {
                $elemMatch: {
                  projectId: new BSON.ObjectID(projectId),
                },
              },
            },
          }
        )
      } catch (error) {
        console.log(error)
      }

      if (!ret) return null
      const mapConfig: MapConfig = ret.mapConfigs[0]
      return mapConfig
    }

    const updateMapConfig = async () => {
      const lat = map.current?.getCenter().lat
      const lng = map.current?.getCenter().lng
      const zoom = map.current?.getZoom()
      const userId = realmApp.currentUser?.id

      if (lat) setPrintLat(lat)
      if (lng) setPrintLng(lng)
      if (zoom) setPrintZoom(zoom)
      setPrintBbox(map.current?.getBounds())

      try {
        // Update if mapConfig already exist for this user on this project
        const ret = await db.collection("users").findOneAndUpdate(
          {
            userId: userId,
            "mapConfigs.projectId": new BSON.ObjectID(projectId),
          },
          {
            $set: {
              "mapConfigs.$.lat": lat,
              "mapConfigs.$.lng": lng,
              "mapConfigs.$.zoom": zoom,
            },
          },
          { returnNewDocument: true }
        )
        if (!ret) throw "Could not update document"
      } catch (error) {
        console.log(error)

        try {
          // Insert new mapConfig entry on this user for this project
          await db.collection("users").findOneAndUpdate(
            { userId: userId },
            {
              $push: {
                mapConfigs: {
                  projectId: new BSON.ObjectID(projectId),
                  lat: lat,
                  lng: lng,
                  zoom: zoom,
                },
              },
            }
          )
        } catch (error) {
          console.log(error)
        }
      }
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current || "",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    })

    map.current?.on("load", async () => {
      map.current?.addSource("norgeskart-topo4", {
        type: "raster",
        tiles: [
          "https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=toporaster4&zoom={z}&x={x}&y={y}",
        ],
        tileSize: 128,
      })

      map.current?.addLayer({
        id: "norgeskart-topo4",
        type: "raster",
        source: "norgeskart-topo4",
        paint: {},
      })

      map.current?.addControl(draw.current, "top-left")

      const _properties = await fetchProperties()
      if (!_properties) return
      if (_properties.length === 0) {
        setDisabledMessageOpen(true)
        return
      }
      await renderProperties(_properties)

      const mapConfig = await fetchMapConfig()
      if (mapConfig) {
        // Set map center based on what is saved on user for this project
        setLng(mapConfig.lng)
        setLat(mapConfig.lat)
        setZoom(mapConfig.zoom)
      } else {
        // Set map center beased on geojsons position
        let lngLat = null
        for (let i = 0; i < _properties.length; i++) {
          if (Array.isArray(_properties[i].geojson)) {
            const geometry = _properties[i].geojson?.at(0)?.geometry
            if (geometry?.type === "Polygon") {
              lngLat = geometry.coordinates[0][0]
            }
          }

          if (lngLat) break
        }
        if (lngLat) {
          setLng(lngLat[0])
          setLat(lngLat[1])
          setZoom(14)
        }
      }
    })

    map.current?.on("dragend", updateMapConfig).on("zoomend", updateMapConfig)

    map.current?.on("draw.create", async (event) => {
      let features: FeatureWithId[] = []
      if (selectedProperty.current?.geojson) {
        features = [...selectedProperty.current.geojson]
      }

      const newFeature = event.features[0]
      features.push(newFeature)
      try {
        await db
          .collection("properties")
          .findOneAndUpdate(
            { _id: selectedProperty.current?._id },
            { $set: { geojson: features } }
          )
      } catch (e) {
        console.log(e)
      }

      const _properties = await fetchProperties()
      if (_properties) renderProperties(_properties)

      selectedProperty.current =
        _properties?.filter((p) => {
          return p._id?.equals(selectedProperty.current?._id || "")
        })[0] || null
    })

    map.current?.on("draw.selectionchange", async (event) => {
      let feature
      if (event.features.length !== 1) {
        feature = null
      } else {
        feature = event.features[0]
      }

      setSelectedFeature(feature)
    })

    map.current?.on("draw.delete", async (event) => {
      if (!selectedProperty.current) return
      await removeFeatureFromProperty(
        event.features[0],
        selectedProperty.current
      )

      // fetch and render
      const _properties = await fetchProperties()
      if (_properties) renderProperties(_properties)
    })

    map.current?.on("draw.update", async (event) => {
      if (!selectedProperty.current) return
      await updateFeatureOnProperty(event.features[0], selectedProperty.current)

      // fetch and render
      const _properties = await fetchProperties()
      if (_properties) renderProperties(_properties)
    })
  }, [db, lat, lng, zoom, realmApp.currentUser?.id, projectId])

  // Set center and zoom when fetched from db
  useEffect(() => {
    map.current?.setCenter([lng, lat])
    map.current?.setZoom(zoom)
  }, [lng, lat, zoom])

  const selectedProperty = useRef<Property | null>(null)

  const handlePropertySelection = async (property: Property) => {
    selectedProperty.current = property
    setSelectedFeature(null)
    draw.current?.changeMode("simple_select")

    const _properties = await fetchProperties()
    if (_properties) renderProperties(_properties)
  }

  const renderProperties = async (properties: Property[]) => {
    // Render properties
    properties.forEach((property: Property, index) => {
      if (!property.geojson || !Array.isArray(property.geojson)) return

      let isSelected = false
      if (selectedProperty.current && property._id) {
        isSelected = selectedProperty.current._id?.equals(property._id) || false
      }

      property.geojson?.forEach((feature) => {
        draw?.current.add(feature)
        draw?.current.setFeatureProperty(
          String(feature.id),
          "isSelected",
          isSelected
        )
        draw?.current.setFeatureProperty(
          String(feature.id),
          "propertyOfFeature",
          index
        )

        draw?.current.setFeatureProperty(
          String(feature.id),
          "propertyNameOfFeature",
          property.name
        )

        // Make sure Draw can read the harvestIsAllowed property so that it applies the correct styling
        if (feature?.properties?.harvestIsAllowed === "no") {
          draw?.current.setFeatureProperty(
            String(feature.id),
            "harvestIsAllowed",
            false
          )
        } else if (feature?.properties?.harvestIsAllowed === "yes") {
          draw?.current.setFeatureProperty(
            String(feature.id),
            "harvestIsAllowed",
            true
          )
        }
      })
    })
  }

  const [selectedFeature, setSelectedFeature] = useState<FeatureWithId | null>(
    null
  )

  const handleHarvestIsAllowedChange = async (
    feature: FeatureWithId,
    value: string
  ) => {
    // find property with feature
    const property = properties.filter((property) => {
      if (!property.geojson) return false
      for (let f of property.geojson) {
        if (f.id === feature.id) return true
      }
      return false
    })[0]

    // update property in db
    try {
      await db
        .collection("properties")
        .findOneAndUpdate(
          { _id: property._id, "geojson.id": feature.id },
          { $set: { "geojson.$.properties.harvestIsAllowed": value } }
        )
    } catch (e) {
      console.log(e)
    }

    // fetch and render
    const _properties = await fetchProperties()
    if (_properties) renderProperties(_properties)
  }

  const removeFeatureFromProperty = async (
    feature: FeatureWithId,
    property: Property
  ) => {
    console.log(property)
    console.log(feature)
    try {
      await db
        .collection("properties")
        .findOneAndUpdate(
          { _id: property._id },
          { $pull: { geojson: { id: feature.id } } }
        )
    } catch (e) {
      console.log(e)
    }
  }

  const updateFeatureOnProperty = async (
    feature: FeatureWithId,
    property: Property
  ) => {
    try {
      await db
        .collection("properties")
        .findOneAndUpdate(
          { _id: property._id, "geojson.id": feature.id },
          { $set: { "geojson.$": feature } }
        )
    } catch (e) {
      console.log(e)
    }
  }

  const [showDialogs, setShowDialogs] = useState<boolean>(true)

  return (
    <>
      <Box
        id="mainPage"
        sx={{
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div ref={mapContainer} className="map-container" />
        </Box>

        <Stack
          sx={{
            position: "absolute",
            right: 1,
            top: "64px",
            p: 2,
          }}
          spacing={2}
        >
          <Button
            variant="contained"
            endIcon={<Print />}
            onClick={() => {
              window.print()
            }}
          >
            Print the map
          </Button>

          <Button
            startIcon={<ArrowForward />}
            variant="contained"
            size="small"
            onClick={() => {
              setShowDialogs(false)
            }}
            sx={{ display: showDialogs ? "block" : "none" }}
          >
            Hide dialogs
          </Button>

          <Button
            startIcon={<ArrowBack />}
            variant="contained"
            size="small"
            onClick={() => {
              setShowDialogs(true)
            }}
            sx={{ display: showDialogs ? "none" : "block" }}
          >
            Show dialogs
          </Button>

          <Paper
            sx={{
              p: 2,
              display: showDialogs ? "block" : "none",
            }}
          >
            <SelectPropertyDialog
              properties={properties}
              propertyNumbers={propertyNumbers}
              owners={propertyOwners}
              onSelectProperty={handlePropertySelection}
            />
          </Paper>

          {properties.length && showEditArea ? (
            <Paper
              sx={{
                p: 2,
                display: showDialogs ? "block" : "none",
              }}
            >
              <EditAreaDialog
                selectedFeature={selectedFeature}
                onHarvestIsAllowedChange={(f, v) => {
                  handleHarvestIsAllowedChange(f, v)
                }}
              />
            </Paper>
          ) : (
            ""
          )}
        </Stack>
        <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={disabledMessageOpen}
        >
          <Paper
            sx={{
              width: 300,
              height: 150,
              p: 4,
              boxShadow: 10,
            }}
          >
            <Box>You have to create properties before drawing on the map.</Box>
            <Box pt={2}>
              <Button
                variant="contained"
                endIcon={<ArrowForward />}
                LinkComponent={Link}
                href={`/project/${projectId}/owners`}
              >
                Create properties
              </Button>
            </Box>
          </Paper>
        </Backdrop>
      </Box>

      <Box
        id="printableMap"
        sx={{
          position: "fixed",
          top: -10000,
        }}
      >
        <PrintableMap
          properties={properties}
          lat={printLat}
          lng={printLng}
          zoom={printZoom}
          bbox={printBbox}
          mapHeight={printMapHeight}
          mapWidth={printMapWidth}
        />
      </Box>
    </>
  )
}
