import { Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { useState } from "react"
import Property from "../../../types/Property"
import EditPropertyForm from "../EditPropertyForm/EditPropertyForm"
import { Close } from "@mui/icons-material"

interface EditPropertyDialogProps {
  property: Property
  onSave: (property: Property) => void
  onDelete: (property: Property) => void
  onClose: () => void
  open: boolean
}

export default function EditPropertyDialog(props: EditPropertyDialogProps) {
  const [editedProperty, setEditedProperty] = useState<Property>(props.property)

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose()
      }}
    >
      <Stack sx={{ py: 2, px: 4 }} spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            Edit property {props.property.name}
          </Typography>
          <IconButton
            onClick={() => {
              props.onClose()
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box>
          <EditPropertyForm
            property={props.property}
            onChange={(property) => {
              setEditedProperty(property)
            }}
          />
        </Box>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              props.onDelete(editedProperty)
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.onSave(editedProperty)
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
