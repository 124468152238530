import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  AlertProps,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import React, { useEffect, useState } from "react"
import { useRealmApp } from "../../RealmApp/RealmApp"
import SnackbarWrapper from "../../SnackbarWrapper/SnackbarWrapper"
import { ObjectId } from "bson"
import { useMongoDB } from "../../MongoDB/MongoDB"
import Project from "../../../types/Project"

enum userRoles {
  admin = "admin",
  hoster = "hoster",
  tanglandAdmin = "tanglandAdmin",
  innsyn = "innsyn",
}

export default function NewUser() {
  const { db } = useMongoDB()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const realm = useRealmApp()

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)

  const [role, setRole] = useState<string>("hoster")
  const handleSelectChange = (event: SelectChangeEvent) => {
    setRole(event.target.value)
  }

  const [projects, setProjects] = useState<Project[]>([])

  const [connectedProject, setConnectedProject] = useState<ObjectId | null>(
    null
  )

  const handleSelectConnectedProjectChange = (event: SelectChangeEvent) => {
    setConnectedProject(new ObjectId(event.target.value))
  }

  interface INewUser {
    name: string
    email: string
    role: string
    projectId: ObjectId | null
  }
  const [newUser, setNewUser] = useState<INewUser>({
    name: "",
    email: "",
    role: "",
    projectId: null,
  })
  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    // Fetch projects
    try {
      const fetchProjects = async () => {
        const fetchedProjects = await db.collection("projects").find()
        setProjects(fetchedProjects)
      }
      fetchProjects()
    } catch (error) {
      console.log(error)
    }
  }, [db])

  const randomPassword = () => {
    return Math.random().toString(36).slice(-8)
  }

  const createUser = async () => {
    if (role === userRoles.hoster && connectedProject === null) {
      setSnackbar({
        children: "Please select a project for the user",
        severity: "error",
      })
      return
    }

    setIsLoading(true)

    try {
      const password = randomPassword()
      await realm.currentUser?.functions.registerNewUser({
        name: newUser.name,
        email: newUser.email,
        password: password,
        role: role,
        projectId: connectedProject,
      })
      realm.currentUser?.app.emailPasswordAuth.sendResetPasswordEmail(
        newUser.email
      )
    } catch (error) {
      setSnackbar({
        children: "Error creating user",
        severity: "error",
      })
    }

    setIsLoading(false)
    setSnackbar({ children: `Sent invitation to ${newUser.email}` })
  }

  return (
    <div>
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper
        sx={{
          mt: 10,
          mx: "auto",
          width: 600,
          p: 2,
        }}
      >
        <Box textAlign="center">
          <Typography variant="h6" component="h2">
            Create new user
          </Typography>
        </Box>

        <Box
          component="form"
          sx={{ width: 500, mx: "auto", rowGap: 1 }}
          display="grid"
        >
          <Box>
            <TextField
              label="Name"
              name="name"
              value={newUser.name}
              sx={{ width: "100%" }}
              onChange={handleFormChange}
            ></TextField>
          </Box>
          <Box>
            <TextField
              label="E-mail"
              name="email"
              value={newUser.email}
              sx={{ width: "100%" }}
              onChange={handleFormChange}
            ></TextField>
          </Box>
          <Box>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="form-role">Role</InputLabel>
              <Select
                labelId="form-role"
                label="Role"
                value={role}
                onChange={handleSelectChange}
              >
                <MenuItem value={userRoles.admin}>Admin</MenuItem>
                <MenuItem value={userRoles.tanglandAdmin}>
                  Tangland Admin
                </MenuItem>
                <MenuItem value={userRoles.innsyn}>User</MenuItem>
                <MenuItem value={userRoles.hoster}>Høster</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {role === userRoles.hoster && (
            <Box>
              <FormControl sx={{ width: "100%", maxWidth: 500 }}>
                <InputLabel id="form-project">Project</InputLabel>
                <Select
                  labelId="form-project"
                  label="Role"
                  value={connectedProject?.toString() ?? ""}
                  onChange={handleSelectConnectedProjectChange}
                >
                  {projects.map((project) => (
                    <MenuItem value={project._id.toString()}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box sx={{ mx: "auto" }} textAlign="center">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={createUser}
            >
              Create user
            </Button>
          </Box>
        </Box>
      </Paper>
      <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />
    </div>
  )
}
