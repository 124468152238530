import React, { useEffect, useState } from "react"
import {
  DataGrid,
  GridRowsProp,
  GridRowId,
  GridRowModel,
  GridActionsCellItem,
  GridColumns,
} from "@mui/x-data-grid"
import { AlertProps } from "@mui/material/Alert"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import Button from "@mui/material/Button"
import { useMongoDB } from "../../MongoDB/MongoDB"
import { useRealmApp } from "../../RealmApp/RealmApp"
import SnackbarWrapper from "../../SnackbarWrapper/SnackbarWrapper"
import Project from "../../../types/Project"
import { BSON } from "realm-web"

export interface User {
  userId: string
  name: string
  role: string
  email: string
  projectId?: BSON.ObjectId
}

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  if (newRow.name !== oldRow.name) {
    return `Name from '${oldRow.name}' to '${newRow.name}'`
  }
  if (newRow.role !== oldRow.role) {
    return `Role from '${oldRow.role}' to '${newRow.role}'`
  }
  if (newRow.projectId?.toString() !== oldRow.projectId?.toString()) {
    return `Connected project from '${oldRow.projectId?.toString()}' to '${newRow.projectId?.toString()}'`
  }
  return null
}

function computeUpdateQuery(newRow: GridRowModel, oldRow: GridRowModel) {
  let query: any = {}
  if (newRow.name !== oldRow.name) {
    query.name = newRow.name
  }
  if (newRow.role !== oldRow.role) {
    query.role = newRow.role
  }
  if (newRow.projectId?.toString() !== oldRow.projectId?.toString()) {
    query.projectId = newRow.projectId
  }
  return query
}

export default function UsersDataGrid() {
  const [users, setUsers] = React.useState<Array<User> | null>(null)

  const [userToDelete, setUserToDelete] = React.useState<GridRowId | null>(null)

  const realmApp = useRealmApp()

  const { db } = useMongoDB()

  React.useEffect(() => {
    const fetchUsers = async () => {
      if (db == null) return
      try {
        const users = await db.collection("users").find()

        setUsers(users)
      } catch (error) {
        if (error instanceof Error) {
          setSnackbar({ children: error.message, severity: "error" })
          setUsers([])
        }
      }
    }
    fetchUsers()
  }, [db, realmApp.currentUser])

  const useMutation = (db: any) => {
    return (user: Partial<User>) =>
      new Promise<Partial<User>>(async (resolve, reject) => {
        // if (user.name?.trim() === "") {
        //   reject()
        // } else {
        try {
          const updateSet = computeUpdateQuery(
            promiseArguments.newRow,
            promiseArguments.oldRow
          )
          const updatedUser = await db
            .collection("users")
            .findOneAndUpdate(
              { userId: user.userId },
              { $set: updateSet },
              { returnNewDocument: true }
            )
          const updatedUsersList = users?.map((user) => {
            if (user.userId === updatedUser.userId) {
              return { ...updatedUser }
            }
            return user
          }) as Array<User>

          setUsers(updatedUsersList)
          resolve(user)
        } catch (error) {
          if (error instanceof Error) {
            setSnackbar({ children: error.message, severity: "error" })
          }
        }

        await realmApp.fetchUser()
        // }
      })
  }

  async function deleteUser(userId: GridRowId) {
    if (users !== null) {
      const updatedUsers = users.filter((user: User) => {
        return user.userId !== userId
      })

      // All delete operations on users will trigger an authentication trigger
      if (realmApp.currentUser?.id === userId) {
        // The current user may delete itself (should trigger authentication trigger DELETE server side)
        await realmApp.realmApp.deleteUser(realmApp.currentUser)
      } else if (
        realmApp.currentUser?.customData.role === "admin" ||
        realmApp.currentUser?.customData.role === "superAdmin"
      ) {
        // If the current user is an admin it may delete other accounts on their behalf (should trigger authentication trigger DELETE server side)
        await realmApp.currentUser?.functions.deleteUser(userId)
      }

      //await realmApp.deleteUser()
      setUsers(updatedUsers)
    }
  }

  const mutateRow = useMutation(db)
  const noButtonRef = React.useRef<HTMLButtonElement>(null)
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null)

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow)
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow })
        } else {
          resolve(oldRow) // Nothing was changed
        }
      }),
    []
  )

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments
    resolve(oldRow) // Resolve with the old row to not update the internal state
    setPromiseArguments(null)
  }

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments

    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow)
      setSnackbar({ children: "User successfully saved", severity: "success" })
      resolve(response)
      setPromiseArguments(null)
    } catch (error) {
      setSnackbar({ children: "Name can't be empty", severity: "error" })
      reject(oldRow)
      setPromiseArguments(null)
    }
  }

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  }

  const handleDeleteNo = () => {
    setUserToDelete(null)
  }

  const handleDeleteYes = () => {
    if (!userToDelete) {
      return null
    }
    try {
      deleteUser(userToDelete)
      setSnackbar({
        children: "User successfully deleted",
        severity: "success",
      })
    } catch (error) {
      setSnackbar({ children: "An error occurred", severity: "error" })
    } finally {
      setUserToDelete(null)
    }
  }

  const [projectOptions, setProjectOptions] = useState<
    { value: BSON.ObjectId; label: string }[]
  >([{ value: new BSON.ObjectId(), label: "No project" }])

  const fetchProjects = async () => {
    let fetchedProjectsTyped: Project[] = []
    try {
      const fetchedProjects = await db.collection("projects").find()
      fetchedProjects.forEach(async (p: any) => {
        fetchedProjectsTyped.push({
          _id: p._id,
          name: p.name,
          status: p.status,
        })
      })
    } catch (error) {
      if (error instanceof Error) {
        setSnackbar({ children: error.message, severity: "error" })
      }
    }

    const projectOptions = [{ value: new BSON.ObjectId(), label: "No project" }]
    for (let i = 0; i < fetchedProjectsTyped.length; i++) {
      projectOptions.push({
        value: fetchedProjectsTyped[i]._id,
        label: fetchedProjectsTyped[i].name,
      })
    }

    setProjectOptions(projectOptions)
  }

  useEffect(() => {
    if (!db) return
    fetchProjects()
  }, [db])

  const renderConfirmDeleteDialog = () => {
    if (!userToDelete) {
      return null
    }

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!userToDelete}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will permenantly delete user with ID ${userToDelete}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleDeleteNo}>
            No
          </Button>
          <Button onClick={handleDeleteYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderConfirmEditDialog = () => {
    if (!promiseArguments) {
      return null
    }

    const { newRow, oldRow } = promiseArguments
    const mutation = computeMutation(newRow, oldRow)

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    )
  }

  let userRows: GridRowsProp = [] as GridRowsProp
  if (users) {
    // If users exist
    userRows = users.map((user) => {
      return {
        id: user.userId,
        userId: user.userId,
        email: user.email,
        name: user.name,
        role: user.role,
        projectId: user.projectId,
      }
    })
  }

  const columns: GridColumns = [
    //{ field: 'userId', headerName: 'User ID', flex: 1 },
    { field: "email", headerName: "E-mail", flex: 1 },
    { field: "name", headerName: "Name", flex: 1, editable: true },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["admin", "tanglandAdmin", "hoster", "innsyn"],
    },
    {
      field: "projectId",
      headerName: "Connected project (for harvesters)",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: projectOptions,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return projectOptions.find((opt) => opt.value.equals(value))?.label
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => setUserToDelete(params.id)}
        />,
      ],
    },
  ]
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          height: 800,
          padding: "50px",
          maxWidth: "1920px",
          flexGrow: 1,
        }}
      >
        {renderConfirmEditDialog()}
        {renderConfirmDeleteDialog()}
        <DataGrid
          rows={userRows}
          columns={columns}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.error(error)
          }}
          experimentalFeatures={{ newEditingApi: true }}
          loading={users ? false : true}
        />
        <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />
      </div>
    </div>
  )
}
