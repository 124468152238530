import React from "react"
import { Button } from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
import { useRealmApp } from "../RealmApp/RealmApp"

export default function LogoutButton() {
  const realm = useRealmApp()

  const logoutCurrentUser = async () => {
    await realm.logOut()
    console.log("log out")
    window.location.reload()
  }

  return (
    <Button
      variant="contained"
      endIcon={<LogoutIcon />}
      onClick={logoutCurrentUser}
    >
      Log out
    </Button>
  )
}
