import { Backdrop, Box, CircularProgress, Stack } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { BSON } from "realm-web"
import Invoice from "../../types/Invoice"
import Project from "../../types/Project"
import PropertyNumber from "../../types/PropertyNumber"
import PropertyOwner from "../../types/PropertyOwner"
import Property from "../../types/Property"
import { useMongoDB } from "../MongoDB/MongoDB"
import InvoiceRow from "./InvoiceRow/InvoiceRow"

interface Props {
  projectIdString?: string
}

interface ProjectWithId extends Project {
  _id: BSON.ObjectID
}

export default function InvoicesView(props: Props) {
  const projectId = new BSON.ObjectID(props.projectIdString)

  const { db } = useMongoDB()

  const [project, setProject] = useState<Project>()

  const [properties, setProperties] = useState<Property[]>([])
  const [propertyNumbers, setPropertyNumbers] = useState<PropertyNumber[]>([])
  const [propertyOwners, setPropertyOwners] = useState<PropertyOwner[]>([])

  const [invoices, setInvoices] = useState<Invoice[]>([])

  const [loadingScreenOpen, setLoadingScreenOpen] = useState<boolean>(true)

  const fetchProject = async () => {
    try {
      const fetchedProject = await db
        .collection("projects")
        .findOne({ _id: projectId })
      setProject(fetchedProject)
    } catch (error) {
      if (error instanceof Error) {
        console.log(error)
      }
    }
  }

  const fetchProperties = useCallback(async () => {
    setLoadingScreenOpen(true)
    // Fetch basic property info
    let properties: Property[]
    try {
      properties = await db
        .collection("properties")
        .find({ projectId: projectId })
    } catch (error) {
      console.log(error)
      return
    }

    // Fetch property numbers for each property
    let propertyNumbers: PropertyNumber[] = []
    for (let i = 0; i < properties.length; i++) {
      const propertyNumberIdsSubset = properties[i].propertyNumberIds || []
      let propertyNumbersSubset: PropertyNumber[] = []
      for (let j = 0; j < propertyNumberIdsSubset.length; j++) {
        try {
          const onePropertyNumber = await db
            .collection("propertyNumbers")
            .findOne({ _id: propertyNumberIdsSubset[j] })
          propertyNumbersSubset.push(onePropertyNumber)
        } catch (error) {
          console.log(error)
          return
        }
      }
      propertyNumbers.push(...propertyNumbersSubset)
    }

    // Fetch owners for each property number
    let propertyOwners: PropertyOwner[] = []
    try {
      propertyOwners = await db
        .collection("propertyOwners")
        .find({ projectId: projectId })
    } catch (error) {
      console.log(error)
      return
    }

    setProperties(properties)
    setPropertyNumbers(propertyNumbers)
    setPropertyOwners(propertyOwners)

    setLoadingScreenOpen(false)
  }, [db])

  useEffect(() => {
    if (!db) return

    fetchProject()
    fetchProperties()
  }, [db])

  const generateInvoices = async () => {
    let _invoices: Invoice[] = []

    let bagsHarvestedOnProject = 0
    for (let i = 0; i < properties.length; i++) {
      const bagsHarvestedOnProperty = properties[i].bagsHarvested || 0
      bagsHarvestedOnProject += bagsHarvestedOnProperty
    }

    for (let i = 0; i < properties.length; i++) {
      const propertyNumbersOnProperty: PropertyNumber[] =
        propertyNumbers.filter((pn) =>
          properties[i].propertyNumberIds
            ?.toString()
            .includes(pn._id.toString())
        )
      for (let j = 0; j < propertyNumbersOnProperty?.length; j++) {
        if (!propertyNumbersOnProperty[j].owners) continue
        if (!propertyNumbersOnProperty[j].owners!.length) continue
        for (let k = 0; k < propertyNumbersOnProperty[j].owners!.length; k++) {
          const ownerId = propertyNumbersOnProperty[j].owners![k].ownerId
          const owner = propertyOwners.find(
            (po) => po._id.toString() === ownerId?.toString()
          )
          const propertyNumberString =
            propertyNumbersOnProperty[j].propertyNumber
          const propertyShare =
            (Math.round((1 / propertyNumbersOnProperty.length) * 100) / 100) *
            (propertyNumbersOnProperty[j].owners![k].share || 0 / 100)

          const tonnesHarvestedOnProperty =
            Math.round(
              (((project?.tonnesHarvested || 0) / bagsHarvestedOnProject) *
                (properties[i].bagsHarvested || 0) *
                (propertyShare / 100 || 0) || 0) * 100
            ) / 100

          const tonnePrice = project?.tonnePrice || 0
          const totalPriceForProperty =
            Math.round(tonnesHarvestedOnProperty * tonnePrice * 100) / 100

          if (!owner) continue
          if (!ownerId) continue

          const invoicePropertyEntry = {
            propertyNumber: propertyNumberString,
            propertyNumbers: propertyNumbersOnProperty.map(
              (pn) => pn.propertyNumber
            ),
            propertyShare: propertyShare,
            tonnesHarvested: tonnesHarvestedOnProperty,
            totalPrice: totalPriceForProperty,
          }

          const existingInvoiceOnOwner = _invoices.filter((invoice) => {
            return invoice.owner._id.toString() === ownerId.toString()
          })[0]

          if (!existingInvoiceOnOwner) {
            const totalPriceWithoutVat = totalPriceForProperty
            const vat = totalPriceWithoutVat * 0.25
            const totalPriceWithVat = totalPriceWithoutVat + vat

            const invoice: Invoice = {
              owner: owner,
              properties: [invoicePropertyEntry],
              tonnePrice: tonnePrice,
              totalPriceWithoutVat: totalPriceForProperty,
              vat: vat,
              totalPriceWithVat: totalPriceWithVat,
              invoiceDate: new Date(),
              dueDate: new Date(
                new Date().getTime() + 14 * 24 * 60 * 60 * 1000
              ),
              invoiceNumber: 0,
            }
            _invoices.push(invoice)
          } else {
            existingInvoiceOnOwner.totalPriceWithoutVat += totalPriceForProperty
            existingInvoiceOnOwner.vat =
              existingInvoiceOnOwner.totalPriceWithoutVat * 0.25
            existingInvoiceOnOwner.totalPriceWithVat =
              existingInvoiceOnOwner.totalPriceWithoutVat +
              existingInvoiceOnOwner.vat

            existingInvoiceOnOwner.properties.push(invoicePropertyEntry)
          }
        }
      }
    }

    setInvoices(_invoices)
  }

  useEffect(() => {
    if (!project) return
    if (!properties) return

    generateInvoices()
  }, [properties, project])

  return (
    <div>
      <Stack
        sx={{
          pt: 2,
          bgcolor: "#ccc",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          {invoices.map((invoice, key) => (
            <Box
              key={key}
              sx={{
                background: "#fff",
                py: 1,
                m: 1,
                p: 1,
                borderRadius: 1,
                boxShadow: 1,
                width: "95%",
                maxWidth: "1100px",
              }}
            >
              <InvoiceRow invoice={invoice} />
            </Box>
          ))}
        </Box>
      </Stack>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingScreenOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
