import BagDrop from "../../../types/BagDrop"
import DialogWithAction from "../../DialogWithAction/DialogWithAction"

interface Props {
  show: boolean
  bagDrop: BagDrop
  onConfirm: () => void
  onCancel: () => void
}

export default function UndoBagDropDialog(props: Props) {
  const lat = props.bagDrop.geojson.geometry.coordinates[0]
  const lon = props.bagDrop.geojson.geometry.coordinates[0]
  const time = props.bagDrop.dropTime.toTimeString()
  return (
    <DialogWithAction
      show={props.show}
      actionTitle="Undo bag drop"
      actionDescription={`This will permanently delete the bag dropped at the coordinates (${lat}, ${lon}) at ${time}?`}
      textOptionB="Yes"
      textOptionA="No"
      actionOptionB={props.onConfirm}
      actionOptionA={props.onCancel}
      bigButtons={true}
    />
  )
}
