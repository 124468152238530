import React from "react"
import { Navigate, useLocation, Outlet } from "react-router-dom"
import { useRealmApp } from "../RealmApp/RealmApp"

export default function PrivateRoute() {
  const { realmApp } = useRealmApp()
  const location = useLocation()
  const { appId } = require("../../realm.json")

  const accessTokenKeyName = (appId: string, userId: string | undefined) => {
    return `realm-web:app(${appId}):user(${userId}):accessToken`
  }

  React.useEffect(() => {
    const handleInvalidAuth = (e: StorageEvent) => {
      //console.log(e.key)
      //console.log(`should be: realm-web:app(${appId}):user(${realmApp.currentUser?.id}):accessToken`)
      if (
        e.key === accessTokenKeyName(appId, realmApp.currentUser?.id) &&
        e.oldValue &&
        !e.newValue
      ) {
        // Refresh the page
        window.location.reload()
        // Refreshing the page will update the realmApp.currentUser variable to null (see RealmApp.tsx)
      }
    }
    window.addEventListener("storage", handleInvalidAuth)
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidAuth)
    }
  }, [realmApp.currentUser, appId])
  // Navigate to desired page if currentUser exists, else navigate to login page
  return realmApp.currentUser ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirectTo=${encodeURI(location.pathname)}`} />
  )
}
