import { AlertProps, Box, Tab, Tabs } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { BSON } from "realm-web"
import Header from "../Header/Header"
import InvoicesView from "../InvoicesView/InvoicesView"
import MapView from "../MapView/MapView"
import { useMongoDB } from "../MongoDB/MongoDB"
import PropertyOwnersView from "../PropertyOwnersView/PropertyOwnersView"
import SnackbarWrapper from "../SnackbarWrapper/SnackbarWrapper"
import Project from "../../types/Project"
import HarvesterView from "../HarvesterView/HarvesterView"
import { useRealmApp } from "../RealmApp/RealmApp"

export default function ProjectPage() {
  let { id, tab } = useParams()

  const [project, setProject] = useState<Project | null>(null)
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null)

  const { db } = useMongoDB()

  const fetchProject = async () => {
    try {
      const fetchedProject = await db
        .collection("projects")
        .findOne({ _id: new BSON.ObjectID(id) })
      setProject(fetchedProject)
    } catch (error) {
      if (error instanceof Error) {
        setSnackbar({ children: error.message, severity: "error" })
      }
    }
  }

  useEffect(() => {
    if (id && db) {
      fetchProject()
    }
  }, [id, db])

  const [activeTab, setActiveTab] = useState<string>("owners")

  useEffect(() => {
    if (!tab) return
    setActiveTab(tab)
  }, [tab])

  const navigate = useNavigate()
  const changeTab = function (event: React.SyntheticEvent, tabValue: string) {
    setActiveTab(tabValue)
    navigate(`/project/${id}/${tabValue}`, { replace: true })
  }

  const { currentUser } = useRealmApp()
  const userRole = currentUser?.customData.role

  const showPropertiesAndOwners =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"
  const showDrawPropertyMap =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"
  const showInvoices =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn"
  const showHarvesterMap =
    userRole === "admin" ||
    userRole === "tanglandAdmin" ||
    userRole === "innsyn" ||
    userRole === "hoster"

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "#ccc",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header title={`${project?.name}`}>
        <Box
          sx={{
            color: "#fff",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={changeTab}
            textColor="inherit"
            TabIndicatorProps={{ style: { backgroundColor: "#fff" } }}
          >
            {showPropertiesAndOwners && (
              <Tab value="owners" label="Properties and Owners" />
            )}
            {showDrawPropertyMap && <Tab value="map" label="Draw Map" />}
            {showInvoices && <Tab value="invoices" label="Invoices" />}
            {showHarvesterMap && (
              <Tab value="harvester-map" label="Harvester Map" />
            )}
          </Tabs>
        </Box>
      </Header>

      {activeTab == "owners" ? <PropertyOwnersView projectIdString={id} /> : ""}
      {activeTab == "map" ? <MapView projectIdString={id} /> : ""}
      {activeTab == "invoices" ? <InvoicesView projectIdString={id} /> : ""}
      {activeTab == "harvester-map" ? <HarvesterView projectId={id} /> : ""}

      <SnackbarWrapper snackbar={snackbar} setSnackbar={setSnackbar} />
    </Box>
  )
}
