import { Box, Button, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import Project, { ProjectStatus } from "../../../types/Project"
import EditProjectForm from "../EditProjectForm/EditProjectForm"
import { BSON } from "realm-web"

interface Props {
  onSave: (project: Project) => void
  onCancel: () => void
}

const emptyProject: Project = {
  _id: new BSON.ObjectID(),
  name: "",
  status: ProjectStatus.UNKNOWN,
  tonnePrice: undefined,
  tonnesHarvested: undefined,
  createdDate: new Date(),
}

export default function NewProjectDialog(props: Props) {
  const [newProject, setNewProject] = useState<Project>(emptyProject)

  return (
    <div>
      <Box sx={{ py: 2, px: 4 }}>
        <Typography variant="h6" component="h2">
          Create new project
        </Typography>

        <Box>
          <EditProjectForm
            project={emptyProject}
            onChange={(project) => {
              setNewProject({ ...project, createdDate: new Date() })
            }}
          />
        </Box>

        <Stack direction="row" spacing={1} pt={1} justifyContent="center">
          <Button
            variant="outlined"
            onClick={() => {
              props.onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.onSave({ ...newProject, _id: new BSON.ObjectID() })
            }}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </div>
  )
}
