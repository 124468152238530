import { Button, Typography } from "@mui/material"
import { Undo } from "@mui/icons-material"

interface Props {
  onClick: () => void
  disabled: boolean
}

export default function UndoBagDropButton(props: Props) {
  return (
    <Button
      variant="outlined"
      size="large"
      sx={{
        width: 200,
        height: 75,
        borderRadius: 4,
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <Undo
        sx={{
          fontSize: 40,
        }}
      />
      <Typography variant="h5">Undo</Typography>
    </Button>
  )
}
