import { Box, Button, Stack, Typography } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import PropertyOwner from "../../../types/PropertyOwner"

interface Props {
  propertyOwner: PropertyOwner
  updatePropertyOwner: (propertyOwner: PropertyOwner) => Promise<void>
  setChildButtonPress: Dispatch<SetStateAction<string>>
  setPropertyOwner: Dispatch<SetStateAction<PropertyOwner | null>>
  setPageStep: Dispatch<SetStateAction<number>>
  tempGdprConsent: boolean
}

export default function GDPRConsentConfirmation(props: Props) {
  const onClickYes = async () => {
    if (props.tempGdprConsent) {
      await props.updatePropertyOwner({
        ...props.propertyOwner,
        gdprConsent: true,
      })
    } else if (!props.tempGdprConsent) {
      await props.updatePropertyOwner({
        ...props.propertyOwner,
        gdprConsent: false,
      })
    }
    props.setChildButtonPress("Godkjenn")
    setTimeout(() => {
      props.setPageStep(1)
    }, 1000)
  }

  const onClickNo = async () => {
    props.setPageStep(1)
    props.setChildButtonPress("Endre")
  }

  const conditionalGdprAnswer = () => {
    if (props.tempGdprConsent) {
      return (
        <Box>
          <Typography
            variant="body1"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            Samtykke til høsting:
          </Typography>
          <Typography variant="body1" align="center">
            Ja
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            Kontonummer:
          </Typography>
          <Typography variant="body2" align="center">
            {props.propertyOwner?.bankAccount}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ fontWeight: "bold", mt: 2 }}
          >
            GDPR:
          </Typography>
          <Typography variant="body1" align="center">
            Godkjent
          </Typography>
        </Box>
      )
    } else if (!props.propertyOwner?.gdprConsent) {
      return (
        <Box>
          <Typography
            variant="body1"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            Samtykke til høsting:
          </Typography>
          <Typography variant="body1" align="center">
            Nei
          </Typography>
        </Box>
      )
    }
  }

  return (
    <Box
      sx={{
        background: "#fff",
        py: 1,
        m: 1,
        p: 2,
        borderRadius: 3,
        boxShadow: 1,
        width: "80%",
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{}}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box
          component="img"
          sx={{
            height: 130,
            width: 100,
          }}
          src="/logo.svg"
        />
        <Typography variant="body1" align="center" mb={1}>
          Svaret ditt har blitt lagret:
        </Typography>
        {conditionalGdprAnswer()}
        <Stack
          direction="row"
          gap={4}
          sx={{
            pt: 4,
          }}
        >
          <Button
            size="large"
            variant="outlined"
            onClick={onClickNo}
            sx={{ borderRadius: 3 }}
          >
            Endre
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={onClickYes}
            sx={{
              borderRadius: 3,
            }}
          >
            Godkjenn
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
