import { Box, Paper, SxProps, Typography } from "@mui/material"
import { ReactNode } from "react"

interface Props {
  title: string
  description?: ReactNode
  value: number
  sx?: SxProps
}
export default function StatsCard(props: Props) {
  return (
    <Paper sx={props.sx}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1">{props.title}</Typography>
        {props.description}
      </Box>

      <Typography variant="h3">{props.value}</Typography>
    </Paper>
  )
}
